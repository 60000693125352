import { AUTH_STORE_KEY } from './../constant/constant';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AppStorageService } from './app.storage.service';

@Injectable({ providedIn: 'root'})
export class RestClient {

    constructor(private _http: HttpClient, private appStorageService: AppStorageService) {

    }

    private get _headers(): any {
        const token = this.appStorageService.getData(AUTH_STORE_KEY)
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            })
        };
        return httpOptions;
    }

    public get _headers_sms(): any {
        const token = '85eecc93-9a55-4141-8a99-6279fe5faf2a';
        const httpOptions = {
            headers: new HttpHeaders({  
                'Access-Control-Allow-Origin':'*,*',
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            })
        };
        return httpOptions;
    }

    getFromFile(url: string): any {
        return this._http.get(url, this._headers);
    }

    get(url: string): Observable<any> {
        try {
            return this._http.get<Observable<any>>(url, this._headers);
        } catch (err) {
            return throwError(err.message);
        }
    }


    post(url: string, data: any): Observable<any> {
        try {
            return this._http.post<any>(url, data, this._headers);
        } catch (err) {
            return throwError(err.message);
        }
    }

    postSms(url: string, data: any): Observable<any> {
        console.log(url);
        console.log(data);
        console.log(this._headers_sms);
        try {
            return this._http.post<any>(url, data, this._headers_sms);
        } catch (err) {
            return throwError(err.message);
        }
    }
}
