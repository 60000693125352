<div class="card">
  <div class="card-header">
    <div class="logo">
      <img
        src="../../../assets/images/beemabox-square.jpg"
        alt="Beemabox Logo"
      />
      <p *ngIf="showSignUpPage" translate>Account Sign Up</p>
      <p *ngIf="showSignInPage" translate>Account Sign In</p>
    </div>
    <!-- <button type="button" class="close" (click)="cloasModal()" style="cursor:pointer">X</button> -->
    <button type="submit" class="close btn ml-1" (click)="cloasModal()">
      <i class="far fa-window-close"></i>
    </button>
    <!-- <span><h2 class="text-center">Premium Calculator</h2></span> -->
  </div>

  <div class="card-body">
    <div class="row">
      <div class="col-md-12 text-center">
        <label id="msg"></label>
      </div>
    </div>

    <div class="row"></div>

    <div *ngIf="showSignInPage">
      <form focusInvalidInput [formGroup]="signInForm" (ngSubmit)="doLogin()">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-user"></i></span>
          </div>
          <input
            id="loginId"
            type="text"
            class="form-control"
            placeholder="Enter username"
            formControlName="loginId"
          />
        </div>
        <span
          *ngIf="
            signInForm.get('loginId').touched &&
            signInForm.get('loginId').hasError('required')
          "
        >
          <small style="color: red"> *Enter your username.</small>
        </span>
        <div class="input-group mt-3">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-key"></i></span>
          </div>
          <input
            id="password"
            type="password"
            class="form-control"
            placeholder="Enter password"
            formControlName="password"
          />
        </div>
        <span
          *ngIf="
            signInForm.get('password').touched &&
            signInForm.get('password').hasError('required')
          "
        >
          <small style="color: red"> *Enter your password.</small>
        </span>
        <button
          id="signIn"
          type="submit"
          class="btn btn-block signIn font-weight-bolder mt-4"
        >
          Sign in
        </button>
        <p></p>
      </form>
      <div class="social-signin">
        <button class="btn btn-lg btn-google btn-block text-uppercase" type="submit" (click)="signInWithGoogle()"><i class="fab fa-google mr-2"></i> Sign in with Google</button> 
        <button class="btn btn-lg btn-facebook btn-block text-uppercase" type="submit" (click)="signInWithFB()"><i class="fab fa-facebook-f mr-2"></i> Sign in with Facebook</button>
      </div>
      <hr />
      <div class="signIn-footer">
        <div class="text-center">
          <p>
            <span>Don't have an account? </span>
            <a href="javascript:;" (click)="goToCustomerSignUpPage()"
              ><span>Sign Up</span></a
            >
          </p>
        </div>
        <div class="d-flex justify-content-center">
          <a href="#">Forgot your password?</a>
        </div>
      </div>
    </div>

    <div *ngIf="showSignUpPage">
      <form
        focusInvalidInput
        [formGroup]="signUpForm"
        (ngSubmit)="signUpInformation()"
      >
        <!-- <div class="input-group mt-3">
          <div class="input-group-prepend">
            <span class="input-group-text"
              ><i class="fa fa-user" aria-hidden="true"></i
            ></span>
          </div>
          <input
            #fullName
            id="fullName"
            name="fullName"
            type="text"
            class="form-control"
            placeholder="Enter your full name"
            formControlName="fullName"
            required
          />
        </div>
        <span
          *ngIf="
            f.fullName.invalid ||
            (f.fullName.invalid && (f.fullName.dirty || f.fullName.touched)) ||
            f.fullName.hasError('required')
          "
        >
          <small style="color: red">
            Name is required. Please enter your full name.</small
          >
        </span> -->
        <div class="input-group mt-3">
          <div class="input-group-prepend">
            <span class="input-group-text"
              ><i class="fa fa-phone" aria-hidden="true"></i
            ></span>
          </div>

          <input
            #mobileNo
            id="mobileNo"
            name="mobileNo"
            type="text"
            class="form-control"
            pattern="{{ mobileNoPattern }}"
            placeholder="Enter your mobile no"
            formControlName="mobileNo"
            required
          />
        </div>

        <span
          *ngIf="
            f.mobileNo.invalid ||
            (f.mobileNo.invalid && (f.mobileNo.dirty || f.mobileNo.touched))
          "
        >
          <small style="color: red"
            >Mobile no is required. Please enter your valid mobile no.</small
          >
        </span>

        <div class="input-group mt-3">
          <div class="input-group-prepend">
            <span class="input-group-text"
              ><i class="fa fa-envelope" aria-hidden="true"></i
            ></span>
          </div>
          <input
            #email
            name="email"
            id="email"
            pattern="{{ emailAddressPattern }}"
            (blur)="emailAvailablity($event)"
            type="email"
            class="form-control"
            placeholder="Enter your email"
            [email]="true"
            formControlName="email"
          />
        </div>

        <!-- <span *ngIf="signUpForm.get('email').touched && !emailAvailable"><small style="color: red"> *Email not available.</small></span> -->
        <!-- <span *ngIf="signUpForm.get('email').touched"><small style="color: red"> *Email not available.</small></span> -->
        <div class="input-group mt-3">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-key"></i></span>
          </div>
          <input
            #password
            id="password"
            name="password"
            type="password"
            class="form-control"
            placeholder="Create password"
            formControlName="password"
            required
          />
        </div>
        <span
          *ngIf="
            f.password.invalid ||
            (f.password.invalid && (f.password.dirty || f.password.touched)) ||
            f.password.hasError('required')
          "
        >
          <small style="color: red">
            Password is required. Create your password.</small
          >
        </span>
        <button
          id="signUp"
          type="submit"
          class="btn btn-block signUp font-weight-bolder mt-4"
        >
          Sign up
        </button>
      </form>
      <div class="social-signin">
        <button class="btn btn-lg btn-google btn-block text-uppercase" type="submit" (click)="signInWithGoogle()"><i class="fab fa-google mr-2"></i> Sign up with Google</button> 
        <button class="btn btn-lg btn-facebook btn-block text-uppercase" type="submit" (click)="signInWithFB()"><i class="fab fa-facebook-f mr-2"></i> Sign up with Facebook</button>
      </div>

      <hr />
      <div class="text-center signIn-footer">
        <p>
          Already have an account?
          <a
            id="signInLink"
            href="javascript:;"
            (click)="goToCustomerLoginPage()"
            ><span>Sign in</span></a
          >
        </p>
      </div>
    </div>
  </div>

  <!-- <div class="card-footer">
        <div class="form-group text-center">
            <button type="submit" class="btn btn-success" (click)="apply()">Apply</button>
            <button type="button" class="btn btn-outline-dark ml-1" (click)="cloasModal()"><i class="fa fa-close"></i> Close</button>
        </div>
    </div> -->
</div>
