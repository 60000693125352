<nav class="navbar sticky-top navbar-light">
  <div class="main-container">
    <div class="left-container" *ngIf="buttonIconState == true">
      <a routerLink="/">
        <img
          src="../../../../assets/images/home-page/logo/Beema-icon-logo.png"
          alt="Beemabox Logo"
        />
      </a>
    </div>
    <div class="center-container">
      <div class="nav-icon-container">
        <div class="sidebar-header">
          <a routerLink="/">
            <img
              src="../../../../assets/images/home-page/logo/Beema-icon-logo.png"
              alt="Beemabox Logo"
            />
          </a>
        </div>

        <i
          *ngIf="buttonIconState == false"
          (click)="toggleSidebar()"
          class="fas fa-chevron-circle-left toggle-nav-icon-left"
        ></i>

        <i
          *ngIf="buttonIconState == true"
          (click)="toggleSidebar()"
          class="fas fa-chevron-circle-right"
        ></i>

        <div *ngIf="locale == 'en'" class="dropdown language-dropdown-btn">
          <a
            class="btn btn-outline-secondary dropdown-toggle"
            href="#"
            role="button"
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <!-- <img src="../../../assets/images/dashboard/us-flag.svg" /> -->
            <p>English</p>
          </a>

          <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <li>
              <a
                class="dropdown-item"
                href="javascript:void(0)"
                (click)="changeLangToEnglish()"
              >
                <!-- <img src="../../../assets/images/dashboard/us-flag.svg" class="pr-2"/> -->
                English
              </a>
            </li>
            <li>
              <a
                class="dropdown-item"
                href="javascript:void(0)"
                (click)="changeLangToBangla()"
              >
                <!-- <img src="../../../assets/images/dashboard/bd-flag.svg" class="pr-2"/> -->
                Bangla
              </a>
            </li>
          </ul>
        </div>
        <div *ngIf="locale == 'bn'" class="dropdown language-dropdown-btn">
          <a
            class="btn btn-outline-secondary dropdown-toggle"
            href="#"
            role="button"
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <!-- <img src="../../../assets/images/dashboard/bd-flag.svg" /> -->
            <p>বাংলা</p>
          </a>

          <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <li>
              <a
                class="dropdown-item"
                href="javascript:void(0)"
                (click)="changeLangToEnglish()"
              >
                <!-- <img src="../../../assets/images/dashboard/us-flag.svg" class="pr-2"/> -->
                ইংরেজী</a
              >
            </li>
            <li>
              <a
                class="dropdown-item"
                href="javascript:void(0)"
                (click)="changeLangToBangla()"
              >
                <!-- <img src="../../../assets/images/dashboard/bd-flag.svg" class="pr-2"/> -->
                বাংলা
              </a>
            </li>
          </ul>
        </div>
        <a
          *ngIf="buttonIconState == true"
          class="
            btn btn-outline-secondary
            togle-button-large-resolution
            toggle-nav-icon-right
            mr-1
          "
          (click)="toggleSidebar()"
        >
          <i class="fas fa-chevron-right"></i>
        </a>
      </div>
    </div>
    <div class="right-container">
      <div class="language">
        <div class="user-info">
          <div class="nav-top-item user-name-role">
            <h5>{{ locale === 'en' ? userInfo.nameEn : userInfo.nameBn }}</h5>
            <p>{{ userInfo.roleType | translate }}</p>
          </div>
          <i class="far fa-bell notification-icon"></i>
          <div class="nav-top-item">
            <div class="dropdown">
              <img
                *ngIf="userInfo.userPhotoUrl == null"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                class="user-photo"
                src="../../../assets/images/admin-dashboard/login-tranparent.png"
              />

              <img
                *ngIf="userInfo.userPhotoUrl != null"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                class="user-photo"
                src="{{ userInfo.userPhotoUrl }}"
              />
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li *ngIf="userInfo.userPhotoUrl == null">
                  <a
                    *ngIf="userInfo.userPhotoUrl == null"
                    class="dropdown-item"
                    (click)="goToProfileEditPage()"
                    href="javascript:void(0)"
                  >
                    <img
                      style="height: 70px; width: 70px"
                      src="../../../assets/images/admin-dashboard/login-tranparent.png"
                    />
                  </a>
                  <a
                    *ngIf="userInfo.userPhotoUrl != null"
                    class="dropdown-item"
                    (click)="goToProfileEditPage()"
                    href="javascript:void(0)"
                    ><img
                      style="height: 70px; width: 70px"
                      src="{{ userInfo.userPhotoUrl }}"
                  /></a>
                </li>
                <li *ngIf="userInfo.userPhotoUrl != null">
                  <a class="dropdown-item" href="#"
                    ><img
                      style="height: 70px; width: 70px"
                      src="{{ userInfo.userPhotoUrl }}"
                  /></a>
                </li>
                <li>
                  <h5>
                    {{ locale === 'en' ? userInfo.nameEn : userInfo.nameBn }}
                  </h5>
                </li>
                <li>
                  <p>{{ userInfo.email }}</p>
                </li>
                <li>
                  <div class="d-grid gap-2 col-6 mx-auto">
                    <button
                      (click)="logout()"
                      class="btn btn-secondary signout-btn"
                      type="button"
                    >
                      Logout
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
