import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { BASE_API_URL_COMMON, SECURITY_V1_REGISTRATION_API, SECURITY_V1_VERIFY_SIGN_UP_API } from '../constant/constant';
import { CheckSignUpVerificationModalComponent } from '../modal/check-sign-up-verification-modal/check-sign-up-verification-modal.component';
import { SignUpRequest } from '../model/model/sign-up/sign-up-request';
import { SignUpResponse } from '../model/model/sign-up/sign-up-response';
import { Header } from '../model/request/base-request';
import { RequestHeader } from '../model/request/request-header';
import { VerifySignUpRequest } from '../model/request/verify-sign-up-request';
import { VerifySignUpRequestBody } from '../model/request/verify-sign-up-request-body';
import { SendOtpResponseBody } from '../model/response/send-otp-response-body';
import { VerifySignUpResponse } from '../model/response/verify-sign-up-response';
import { VerifySignUpResponseBody } from '../model/response/verify-sign-up-response-body';

@Injectable({
  providedIn: 'root'
})
export class SignUpService {

  public header: Header = new Header();
  public requestHeader: RequestHeader = new RequestHeader();

  public verifySignUpRequest: VerifySignUpRequest = new VerifySignUpRequest();
  public verifySignUpRequestBody: VerifySignUpRequestBody = new VerifySignUpRequestBody();
  public verifySignUpResponse: VerifySignUpResponse = new VerifySignUpResponse();
  public verifySignUpResponseBody: VerifySignUpResponseBody = new VerifySignUpResponseBody();
  
  constructor(
    private httpClient: HttpClient,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService
  ) {}

  saveSignUpInformation(
    signUpRequest: SignUpRequest
  ): Observable<SignUpResponse> {
    return this.httpClient.post<SignUpResponse>(
      `${BASE_API_URL_COMMON}/${SECURITY_V1_REGISTRATION_API}`,
      signUpRequest
    );
  }

  verifySignUpInformation(
    verifySignUpRequest: VerifySignUpRequest
  ): Observable<VerifySignUpResponse> {
    return this.httpClient.post<VerifySignUpResponse>(
      `${BASE_API_URL_COMMON}/${SECURITY_V1_VERIFY_SIGN_UP_API}`,
      verifySignUpRequest
    );
  }

  checkSignUpVerification(dataObj: SendOtpResponseBody) {
    var obj: any = { };

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '30%';
    dialogConfig.data = { obj };
    console.log(dialogConfig.data);
    var result = "No";
    this.dialog
      .open(CheckSignUpVerificationModalComponent, dialogConfig)
      .afterClosed()
      .subscribe((res: string) => {
        console.log(res);
        if (res != 'Close') {
          console.log('Object Data Found');
          //result = true;
          result = "No";
          return false;
        } else {
          //result = false;
          result = "Yes";
          return true;
        }
      });
      //return result;
      return result;
  }

  verifySignUpByLoginId(loginId: any){
    this.requestHeader.requestId = this.header.requestId;
    this.requestHeader.requestDateTime = this.header.requestDateTime;
    this.requestHeader.requestSource = this.header.requestSource;
    this.requestHeader.requestServiceSource = this.header.requestServiceSource;

    this.verifySignUpRequest.header = this.requestHeader;
    this.verifySignUpRequest.body = this.verifySignUpRequestBody;
    this.verifySignUpRequest.body.loginId = loginId;
    debugger;
    //this.spinner.show();
    var result = true;
    this.verifySignUpInformation(this.verifySignUpRequest).subscribe(
      (data) => {
        debugger;
        console.log(data);
        if (data.header.responseCode == '200') {
          this.verifySignUpResponseBody = data.body;
          console.log(this.verifySignUpResponseBody);
        } else {
          this.verifySignUpResponseBody = null;
          result = false;
        }
      },
      (error) => {
        console.log(error);
        //this.spinner.hide();
        this.verifySignUpResponseBody = null;
        return false;
      }
    );
    //this.spinner.hide();
    return result;
  }
  
}
