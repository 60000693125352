import { Component, OnInit } from '@angular/core';
import { BaseRequest } from '@app/@utlity/model/request/base-request';

@Component({
  selector: 'app-authentication-modal',
  templateUrl: './authentication-modal.component.html',
  styleUrls: ['./authentication-modal.component.scss']
})
export class AuthenticationModalComponent extends BaseRequest implements OnInit {

  constructor() { 
    super();
  }

  ngOnInit(): void {
    
  }

}
