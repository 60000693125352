import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LOGIN_LOCAL_STORAGE_KEY } from '@app/@utlity/constant/constant';
import { OtpConfig } from '@app/@utlity/model/model/otp-config';
import { UserInfo } from '@app/@utlity/model/model/user-info';
import { Header } from '@app/@utlity/model/request/base-request';
import { RequestHeader } from '@app/@utlity/model/request/request-header';
import { ResendOtpRequest } from '@app/@utlity/model/request/resend-otp-request';
import { ResendOtpRequestBody } from '@app/@utlity/model/request/resend-otp-request-body';
import { SendOtpRequest } from '@app/@utlity/model/request/send-otp-request';
import { SendOtpRequestBody } from '@app/@utlity/model/request/send-otp-request-body';
import { VerifyOtpRequest } from '@app/@utlity/model/request/verify-otp-request';
import { VerifyOtpRequestBody } from '@app/@utlity/model/request/verify-otp-request-body';
import { VerifySignUpRequest } from '@app/@utlity/model/request/verify-sign-up-request';
import { VerifySignUpRequestBody } from '@app/@utlity/model/request/verify-sign-up-request-body';
import { SendOtpResponseBody } from '@app/@utlity/model/response/send-otp-response-body';
import { VerifyOtpResponse } from '@app/@utlity/model/response/verify-otp-response';
import { VerifyOtpResponseBody } from '@app/@utlity/model/response/verify-otp-response-body';
import { VerifySignUpResponse } from '@app/@utlity/model/response/verify-sign-up-response';
import { VerifySignUpResponseBody } from '@app/@utlity/model/response/verify-sign-up-response-body';
import { AppStorageService } from '@app/@utlity/services/app.storage.service';
import { OtpService } from '@app/@utlity/services/otp.service';
import { SignUpService } from '@app/@utlity/services/sign-up.service';
import { CountdownComponent } from 'ngx-countdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-check-sign-up-verification-modal',
  templateUrl: './check-sign-up-verification-modal.component.html',
  styleUrls: ['./check-sign-up-verification-modal.component.scss']
})
export class CheckSignUpVerificationModalComponent implements OnInit {

  public userInfo: UserInfo = new UserInfo();
  public countDownTime = "300";
  public countDownConfig:any;
  public otp: string;
  public showWrongOtpMessage = false;
  public showResendOtpMessage = false;
  public showOtpComponent = true;
  public otpVerifyMessage = "";
  @ViewChild('ngOtpInput', { static: false}) ngOtpInput: any;
  @ViewChild('otpCountDown', { static: false }) private countdown: CountdownComponent;
  public otpObj: SendOtpResponseBody = new SendOtpResponseBody();

  public header: Header = new Header();
  public requestHeader: RequestHeader = new RequestHeader();

  public sendOtpRequest: SendOtpRequest = new SendOtpRequest();
  public sendOtpRequestBody: SendOtpRequestBody = new SendOtpRequestBody();

  public resendOtpRequest: ResendOtpRequest = new ResendOtpRequest();
  public resendOtpRequestBody: ResendOtpRequestBody = new ResendOtpRequestBody();

  public verifyOtpRequest: VerifyOtpRequest = new VerifyOtpRequest();
  public verifyOtpRequestBody: VerifyOtpRequestBody = new VerifyOtpRequestBody();
  public verifyOtpResponse: VerifyOtpResponse = new VerifyOtpResponse();
  public verifyOtpResponseBody: VerifyOtpResponseBody = new VerifyOtpResponseBody();

  public verifySignUpRequest: VerifySignUpRequest = new VerifySignUpRequest();
  public verifySignUpRequestBody: VerifySignUpRequestBody = new VerifySignUpRequestBody();
  public verifySignUpResponse: VerifySignUpResponse = new VerifySignUpResponse();
  public verifySignUpResponseBody: VerifySignUpResponseBody = new VerifySignUpResponseBody();

  constructor(
    private _appStorageService: AppStorageService,
    private toastr: ToastrService,
    private _otpService: OtpService,
    private _signUpService: SignUpService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data : any,
    public dialogRef: MatDialogRef<CheckSignUpVerificationModalComponent>
  ) { }

  config :OtpConfig = {
    allowNumbersOnly: false,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '50px',
      'height': '50px'
    }
  };

  onOtpChange(otp: string) {
    this.otp = otp;
    if(this.otp.length == 6){
      this.spinner.show();
      this.verifyOtpByLoginId(this.otpObj.loginId, this.otp);
    }
  }

  setVal(val: any) {
    this.ngOtpInput.setValue(val);
  }

  toggleDisable(){
    if(this.ngOtpInput.otpForm){
      if(this.ngOtpInput.otpForm.disabled){
        this.ngOtpInput.otpForm.enable();
      }else{
        this.ngOtpInput.otpForm.disable();
      }
    }
  }

  onConfigChange() {
    this.showOtpComponent = false;
    this.otp = null;
    setTimeout(() => {
      this.showOtpComponent = true;
    }, 0);
  }

  closeModal(){
    this.dialogRef.close('Close');
  }

  ngOnInit(): void {
    this.spinner.hide();
    this.userInfo = JSON.parse(this._appStorageService.getData(LOGIN_LOCAL_STORAGE_KEY));
    this.otpObj.loginId = this.userInfo.loginId;
    this.otpObj.mobileNo = this.userInfo.mobileNo
    this.countDownConfig = {leftTime: this.countDownTime, format: 'm:s', demand:false};
  }

  verifyOtpByLoginId(loginId: any, otp: any) {
    this.requestHeader.requestId = this.header.requestId;
    this.requestHeader.requestDateTime = this.header.requestDateTime;
    this.requestHeader.requestSource = this.header.requestSource;
    this.requestHeader.requestServiceSource = this.header.requestServiceSource;

    this.verifyOtpRequest.header = this.requestHeader;
    this.verifyOtpRequest.body = this.verifyOtpRequestBody;
    this.verifyOtpRequest.body.loginId = loginId;
    this.verifyOtpRequest.body.otp =  otp;
    console.log(this.verifyOtpRequest);
    
    this._otpService.verifyOtp(this.verifyOtpRequest).subscribe(
      (data) => {
        console.log(data);
        if (data.header.responseCode == '200') {
          if(data.body.data == true){
            //this.otpVerifyMessage = "The OTP you entered is invalid. Please enter correct OTP.";
            this.showWrongOtpMessage = false;
            this.verifyOtpResponseBody = data.body;
            this.verifySignUpByLoginId(loginId);
          } else {
            this.spinner.hide();
            this.showWrongOtpMessage = true;
            this.otpVerifyMessage = "The OTP code has expired. Please re-send the otp code to try again.";
          }
        } else {
          this.otpVerifyMessage = "The OTP you entered is invalid. Please enter correct OTP.";
          this.showWrongOtpMessage = true;
          this.spinner.hide();
          this.toastr.warning('Wrong otp.', '', {
            positionClass: 'toast-top-right', closeButton: true,
          });
        }
      },
      (error) => {
        this.otpVerifyMessage = "The OTP you entered is invalid. Please enter correct OTP.";
        this.showWrongOtpMessage = true;
        this.spinner.hide();
        console.log(error);
      }
    );
  }

  verifySignUpByLoginId(loginId: any){
    this.requestHeader.requestId = this.header.requestId;
    this.requestHeader.requestDateTime = this.header.requestDateTime;
    this.requestHeader.requestSource = this.header.requestSource;
    this.requestHeader.requestServiceSource = this.header.requestServiceSource;

    this.verifySignUpRequest.header = this.requestHeader;
    this.verifySignUpRequest.body = this.verifySignUpRequestBody;
    this.verifySignUpRequest.body.loginId = loginId;

    this._signUpService.verifySignUpInformation(this.verifySignUpRequest).subscribe(
      (data) => {
      this.spinner.hide();
        debugger;
        console.log(data);
        if (data.header.responseCode == '200') {
          this.verifySignUpResponseBody = data.body;
          console.log(this.verifySignUpResponseBody);
          this.dialogRef.close(this.verifySignUpResponseBody);
        } else {
          this.verifySignUpResponseBody = null;
        }
      },
      (error) => {
        console.log(error);
        this.spinner.hide();
        this.verifySignUpResponseBody = null;
      }
    );
  }

  resendOtpByLoginId(){
    this.requestHeader.requestId = this.header.requestId;
    this.requestHeader.requestDateTime = this.header.requestDateTime;
    this.requestHeader.requestSource = this.header.requestSource;
    this.requestHeader.requestServiceSource = this.header.requestServiceSource;

    this.resendOtpRequest.header = this.requestHeader;
    this.resendOtpRequest.body = this.sendOtpRequestBody;
    this.resendOtpRequest.body.loginId = this.otpObj.loginId;
    this.spinner.show(); 
    this._otpService.resendOtp(this.resendOtpRequest).subscribe(
      (data) => {
        this.spinner.hide();
        console.log(data);
        console.log(data.body.otp);
        if (data.header.responseCode == '200') {
          if(data.body.smsStatus == "Sent"){
            this.countDownConfig = {leftTime: this.countDownTime, format: 'm:s', demand:false};
            this.countdown.begin();
            this.showResendOtpMessage = true;
            this.showWrongOtpMessage = false;
            this.otp = "";
            this.setVal(this.otp);
          } else {
            this.showResendOtpMessage = false;
          }
        } else {
        }
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    );
  }


}
