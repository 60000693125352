import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ADMIN_LOGIN_LOCAL_STORAGE_KEY,
  ADMIN_LOGIN_MENUS_LOCAL_STORAGE_KEY,
  BASE_API_URL_COMMON,
  LOGIN_LOCAL_STORAGE_KEY,
  SECURITY_GET_USERINFO_BY_LOGIN_ID_API_V1,
  UPDATE_USER_INFO,
} from '../constant/constant';
import { SignInRequest } from '../model/model/sign-in/sign-in-request';
import { SignInRequestBody } from '../model/model/sign-in/sign-in-request-body';
import { SignInResponse } from '../model/model/sign-in/sign-in-response';
import { UserInfo } from '../model/model/user-info';
import { Header } from '../model/request/base-request';
import { RequestHeader } from '../model/request/request-header';
import { AppStorageService } from './app.storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserInfoService {
  public header: Header = new Header();
  public userInfo: UserInfo = new UserInfo();

  private updateUserInfoSource = new BehaviorSubject<string>('service');
  public updateUserInfoMessage = this.updateUserInfoSource.asObservable();

  constructor(
    private httpClient: HttpClient,
    private _appStorageService: AppStorageService,
    private toastr: ToastrService,
    private router: Router,
    private _spinner: NgxSpinnerService
  ) {}

  getCustomerUserInfoByLoginId(
    request: SignInRequest
  ): Observable<SignInResponse> {
    return this.httpClient.post<SignInResponse>(
      `${BASE_API_URL_COMMON}/${SECURITY_GET_USERINFO_BY_LOGIN_ID_API_V1}`,
      request
    );
  }
  public requestHeader: RequestHeader = new RequestHeader();
  public userInfoRequest: SignInRequest = new SignInRequest();
  public userInfoRequestBody: SignInRequestBody = new SignInRequestBody();

  setUserInfoByLoginId(loginId: string) {
    this.userInfo = JSON.parse(
      this._appStorageService.getData(LOGIN_LOCAL_STORAGE_KEY)
    );

    this.requestHeader.requestId = this.header.requestId;
    this.requestHeader.requestDateTime = this.header.requestDateTime;
    this.requestHeader.requestSource = this.header.requestSource;
    this.requestHeader.requestServiceSource = this.header.requestServiceSource;

    this.userInfoRequest.header = this.requestHeader;
    this.userInfoRequest.body = this.userInfoRequestBody;
    this.userInfoRequest.body.loginId = loginId;
    this._spinner.show();

    this.getCustomerUserInfoByLoginId(this.userInfoRequest).subscribe(
      (data) => {
        this._spinner.hide();
        console.log(data);
        console.log(data);
        if (data.header.responseCode == '200') {
          this.userInfo.nameEn = data.body.nameEn;
          this.userInfo.nameBn = data.body.nameBn;
          this.userInfo.email = data.body.email;
          this.userInfo.mobileNo = data.body.mobileNo;
          this.userInfo.roleType = data.body.roleType;
          this.userInfo.userPhotoPath = data.body.userPhotoPath;
          this.userInfo.userPhotoUrl = data.body.userPhotoUrl;

          this._appStorageService.setData(
            ADMIN_LOGIN_MENUS_LOCAL_STORAGE_KEY,
            data.body.accessJson
          );
          this._appStorageService.setData(
            ADMIN_LOGIN_LOCAL_STORAGE_KEY,
            JSON.stringify(this.userInfo)
          );
          this._appStorageService.setData(
            LOGIN_LOCAL_STORAGE_KEY,
            JSON.stringify(this.userInfo)
          );
          this.updateUserInformation(UPDATE_USER_INFO);
          this.router.navigate(['customer/application/submitted-application-list']);
        } else {
        }
      },
      (error) => {
        this._spinner.hide();
        // this.isError = true;
        console.log(error);
        // setTimeout(()=>{  this.isError = false; }, 5000);
      }
    );
  }

  updateUserInformation(message: string) {
    this.updateUserInfoSource.next(message);
  }
}
