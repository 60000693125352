<header>
  <nav class="navbar navbar-expand-lg navbar-primary bg-light">
    <a class="navbar-brand" translate>APP_NAME</a>
    <button
      class="navbar-toggler"
      type="button"
      aria-controls="navbar-menu"
      aria-label="Toggle navigation"
      (click)="toggleMenu()"
      [attr.aria-expanded]="!menuHidden"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div id="navbar-menu" class="collapse navbar-collapse float-xs-none" [ngbCollapse]="menuHidden">
      <div class="navbar-nav">
        <!-- <a class="nav-item nav-link text-uppercase" routerLink="/home" routerLinkActive="active">
          <i class="fas fa-home"></i>
          <span translate>Home</span>
        </a>
        <a class="nav-item nav-link text-uppercase" routerLink="/about" routerLinkActive="active">
          <i class="fas fa-question-circle"></i>
          <span translate>About</span>
        </a> -->
      </div>
      <div class="navbar-nav ml-auto">
        <app-language-selector inNavbar="true" menuClass="dropdown-menu dropdown-menu-right"></app-language-selector>
        <div class="nav-item" ngbDropdown>
          <a id="user-dropdown" class="nav-link" ngbDropdownToggle>
            <!-- <i class="fas fa-user-circle"></i> -->
            <img src="../../../assets/images/default_avater.jpg" class="user-image">
          </a>
          <div ngbDropdownMenu aria-labelledby="user-dropdown" class="dropdown-menu dropdown-menu-right">
            <h6 class="dropdown-header">
              <span translate>Logged in as</span> <b>{{ username }}</b>
            </h6>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item" (click)="logout()" translate>Logout</button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>
