import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { Logger, untilDestroyed } from '@core';
import { AuthenticationService } from './authentication.service';
import {
  ADMIN_LOGIN_LOCAL_STORAGE_KEY,
  ADMIN_LOGIN_MENUS_LOCAL_STORAGE_KEY,
  APPLICATION_NAME,
  APP_SETTINGS_LIST_LOCAL_STORAGE_KEY,
  APP_SETTINGS_SMS,
  CUSTOMER_LOGIN_LOCAL_STORAGE_KEY,
  LOGIN_LOCAL_STORAGE_KEY,
  mobileNoRegEx,
  ROLE_TYPE_ADMIN,
  ROLE_TYPE_CUSTOMER,
  ROLE_TYPE_INSURER,
  ROLE_TYPE_REGISTERED_USER,
  ROLE_TYPE_UNIT_PROMOTER,
} from '@app/@utlity/constant/constant';
import { AppStorageService } from '@app/@utlity/services/app.storage.service';
import { CustomerLogInRequest } from '@app/web/customer-login/model/customer-log-in-request';
import { CustomerLogInRequestBody } from '@app/web/customer-login/model/customer-log-in-request-body';
import { CustomerLoginService } from '@app/web/customer-login/customer-login.service';
import { ToastrService } from 'ngx-toastr';
import { WebPortalService } from '@app/@utlity/services/web-portal.service';
import { Header } from '@app/@utlity/model/request/base-request';
import { UserInfo } from '@app/@utlity/model/model/user-info';
import { SignUpRequest } from '@app/@utlity/model/model/sign-up/sign-up-request';
import { SignUpRequestBody } from '@app/@utlity/model/model/sign-up/sign-up-request-body';
import { RequestHeader } from '@app/@utlity/model/request/request-header';
import { SignUpService } from '@app/@utlity/services/sign-up.service';
import { SendOtpRequest } from '@app/@utlity/model/request/send-otp-request';
import { SendOtpRequestBody } from '@app/@utlity/model/request/send-otp-request-body';
import { VerifyOtpRequest } from '@app/@utlity/model/request/verify-otp-request';
import { VerifyOtpRequestBody } from '@app/@utlity/model/request/verify-otp-request-body';
import { ResetPasswordRequest } from '@app/@utlity/model/request/reset-password-request';
import { ResetPasswordRequestBody } from '@app/@utlity/model/request/reset-password-request-body';
import { OtpService } from '@app/@utlity/services/otp.service';
import { PasswordService } from '@app/@utlity/services/password.service';
import { AppSettingsListRequestBody } from '@app/@utlity/model/request/app-settings-list-request-body';
import { AppSettingsListRequest } from '@app/@utlity/model/request/app-settings-list-request';
import { AppSettingsService } from '@app/@utlity/services/app-settings.service';
import { AppSettingsInfo } from '@app/@utlity/model/model/app-settings-info';
import * as _ from 'underscore';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConstantService } from '@app/@utlity/services/constant.service';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
  SocialUser,
} from 'angularx-social-login';
import { SignInRequest } from '@app/@utlity/model/model/sign-in/sign-in-request';
import { SignInRequestBody } from '@app/@utlity/model/model/sign-in/sign-in-request-body';
import { UserInfoService } from '@app/@utlity/services/user-info.service';

const log = new Logger('Login');

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  public header: Header = new Header();
  public userInfo: UserInfo = new UserInfo();
  public appSettingsInfo: AppSettingsInfo[];
  public isError: boolean;
  public error: string | undefined;
  public loginForm!: FormGroup;
  public isLoading = false;
  private token: string;
  private authenticationToken: string;
  private userLocationInfo: any;
  public showSignInPage: boolean;
  public showSignUpPage: boolean;
  public showForgotPasswordPage: boolean;
  public showForgotPasswordLoginId: boolean;
  public showForgotPasswordOtp: boolean;
  public showResetPasswordPage: boolean;

  public showSignInFailedMessgae: boolean;
  public showSignUpFailedMessgae: boolean;
  public signUpFailedMessgae: string;
  public locale: any;

  public signInTitle: String;
  public forgotPasswordTitle: string;
  public signUpRequest: SignUpRequest = new SignUpRequest();
  public signUpRequestHeader: RequestHeader = new RequestHeader();
  public signUpRequestBody: SignUpRequestBody = new SignUpRequestBody();

  public signInForm: FormGroup;
  public signUpForm: FormGroup;
  public forgotPasswordForm: FormGroup;
  public resetPasswordForm: FormGroup;

  public emailAvailable: boolean;
  public mobileNoPattern = mobileNoRegEx;
  public emailAddressPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';

  public newSignUp: SignUpRequestBody = new SignUpRequestBody();
  public signInData: CustomerLogInRequestBody = new CustomerLogInRequestBody();
  public forgotPasswordData: VerifyOtpRequestBody = new VerifyOtpRequestBody();
  public resetPasswordData: ResetPasswordRequestBody =
    new ResetPasswordRequestBody();

  public requestHeader: RequestHeader = new RequestHeader();

  public adminLogInRequest: CustomerLogInRequest = new CustomerLogInRequest();
  public adminLogInRequestBody: CustomerLogInRequestBody =
    new CustomerLogInRequestBody();

  public sendOtpRequest: SendOtpRequest = new SendOtpRequest();
  public sendOtpRequestBody: SendOtpRequestBody = new SendOtpRequestBody();

  public verifyOtpRequest: VerifyOtpRequest = new VerifyOtpRequest();
  public verifyOtpRequestBody: VerifyOtpRequestBody =
    new VerifyOtpRequestBody();

  public resetPasswordRequest: ResetPasswordRequest =
    new ResetPasswordRequest();
  public resetPasswordRequestBody: ResetPasswordRequestBody =
    new ResetPasswordRequestBody();

  public appSettingsListRequest: AppSettingsListRequest =
    new AppSettingsListRequest();
  public appSettingsListRequestBody: AppSettingsListRequestBody =
    new AppSettingsListRequestBody();

  // public email: {};
  @ViewChild('fullName') fullName: any;
  @ViewChild('mobileNo') mobileNo: any;
  @ViewChild('email') email: any;
  @ViewChild('password') password: any;
  @ViewChild('loginId') loginId: any;
  @ViewChild('otp') otp: any;
  @ViewChild('newPassword') newPassword: any;
  @ViewChild('confirmNewPassword') confirmNewPassword: any;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private _signUpService: SignUpService,
    private _appStorageService: AppStorageService,
    private customerLogInService: CustomerLoginService,
    private _otpService: OtpService,
    private _passwordService: PasswordService,
    private _webPortalService: WebPortalService,
    private toastr: ToastrService,
    private _appSettingsService: AppSettingsService,
    private _spinner: NgxSpinnerService,
    private constantService: ConstantService,
    private socialAuthService: SocialAuthService,
    private translate: TranslateService,
    private userInfoService: UserInfoService
  ) {
    this.createForm();
  }

  private socialUser: SocialUser;
  public authorized: boolean = false;

  gotoHomePage() {
    this.router.navigate(['/']);
  }

  signInWithGoogle(): void {
    this.socialAuthService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((userData) => {
        // Now sign-in with userData
        if (userData != null) {
          this.authorized = true;
          this.socialUser = userData;
          this.checkUserInfoBySocialUser(this.socialUser.email);
        }
      });
  }

  signInWithFB(): void {
    this.socialAuthService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .then((userData) => {
        // Now sign-in with userData
        if (userData != null) {
          this.authorized = true;
          this.socialUser = userData;
          this.checkUserInfoBySocialUser(this.socialUser.email);
        }
      });
  }

  public userInfoRequest: SignInRequest = new SignInRequest();
  public userInfoRequestBody: SignInRequestBody = new SignInRequestBody();

  checkUserInfoBySocialUser(loginId: string) {
    //this.userInfo = JSON.parse(this._appStorageService.getData(LOGIN_LOCAL_STORAGE_KEY));

    this.requestHeader.requestId = this.header.requestId;
    this.requestHeader.requestDateTime = this.header.requestDateTime;
    this.requestHeader.requestSource = this.header.requestSource;
    this.requestHeader.requestServiceSource = this.header.requestServiceSource;

    this.userInfoRequest.header = this.requestHeader;
    this.userInfoRequest.body = this.userInfoRequestBody;
    this.userInfoRequest.body.loginId = loginId;
    this._spinner.show();

    this.userInfoService
      .getCustomerUserInfoByLoginId(this.userInfoRequest)
      .subscribe(
        (data) => {
          this._spinner.hide();
          debugger;
          if (data.header.responseCode == '200') {
            localStorage.setItem('loggedIn', 'true');
            this._appStorageService.setData(
              LOGIN_LOCAL_STORAGE_KEY,
              JSON.stringify(data.body)
            );
            this._appStorageService.setData(
              ADMIN_LOGIN_MENUS_LOCAL_STORAGE_KEY,
              data.body.accessJson
            );

            if (data.body.roleType == ROLE_TYPE_CUSTOMER) {
              this.router.navigate(['customer/dashboard']);
            } else if (data.body.roleType == ROLE_TYPE_INSURER) {
              this.router.navigate(['insurer/dashboard']);
            } else if (data.body.roleType == ROLE_TYPE_ADMIN) {
              this.router.navigate(['admin/dashboard']);
            } else if (data.body.roleType == ROLE_TYPE_REGISTERED_USER) {
              this.router.navigate(['registered-user/dashboard']);
            }
            /*
          this.userInfo.nameEn = data.body.nameEn;
          this.userInfo.nameBn = data.body.nameBn;
          this.userInfo.email = data.body.email;
          this.userInfo.mobileNo = data.body.mobileNo;
          this.userInfo.roleType = data.body.roleType;
          this.userInfo.userPhotoPath = data.body.userPhotoPath;
          this.userInfo.userPhotoUrl = data.body.userPhotoUrl;

          this._appStorageService.setData(ADMIN_LOGIN_MENUS_LOCAL_STORAGE_KEY, data.body.accessJson);
          this._appStorageService.setData(ADMIN_LOGIN_LOCAL_STORAGE_KEY, JSON.stringify(this.userInfo));
          this._appStorageService.setData(LOGIN_LOCAL_STORAGE_KEY, JSON.stringify(this.userInfo));
          */
            //this.router.navigate(['customer/application/my-applications']);
          }

          if (data != null && data.header.responseCode === 'NoDataFound') {
            this.signUpRequest.header = this.requestHeader;
            this.signUpRequest.body = this.signUpRequestBody;
            this.signUpRequest.body.email = this.socialUser.email;
            this.signUpRequest.body.fullName = this.socialUser.name;
            this.signUpRequest.body.nameEn = this.socialUser.name;
            this.signUpRequest.body.loginId = this.socialUser.email;
            this.signUpRequest.body.photoUrl = this.socialUser.photoUrl;

            this._signUpService
              .saveSignUpInformation(this.signUpRequest)
              .subscribe(
                (data) => {
                  this._spinner.hide();

                  if (
                    data != null &&
                    data.header.responseCode === 'NoDataFound'
                  ) {
                  }

                  if (data != null && data.header.responseCode === '200') {
                    if (data.body.loginStatus == 'OK') {
                      //this._webPortalService.customerUserInfo.next(data.body);
                      this.checkUserInfoBySocialUser(this.socialUser.email);
                    } else {
                      this.toastr.warning('Unable To Sign Up', '', {
                        positionClass: 'toast-top-right',
                        closeButton: true,
                      });
                    }
                  } else {
                    this.toastr.warning('Unable To Sign Up', '', {
                      positionClass: 'toast-top-right',
                      closeButton: true,
                    });
                  }
                },
                (error) => {
                  console.log(error);
                  this._spinner.hide();
                  this.toastr.error(error.message, '', {
                    positionClass: 'toast-top-right',
                    closeButton: true,
                  });
                }
              );
          }
        },
        (error) => {
          this._spinner.hide();
        }
      );
  }

  isValidSignUpForm() {
    this.newSignUp = this.signUpForm.value;
    if (
      !this.newSignUp.mobileNo ||
      this.signUpForm.get('mobileNo').invalid ||
      (this.signUpForm.get('mobileNo').invalid &&
        (this.signUpForm.get('mobileNo').dirty ||
          this.signUpForm.get('mobileNo').touched))
    ) {
      // this.toastr.warning('Please enter your valid mobile no', '', {
      //   positionClass: 'toast-top-right', closeButton: true,
      // });
      this.translate.get('Insurance_Company').subscribe((text: string) => {
        this.toastr.warning(text, '', {
          positionClass: 'toast-top-right',
          closeButton: true,
        });
      });
      this.toastr.warning('Please enter your valid mobile no', '', {
        positionClass: 'toast-top-right',
        closeButton: true,
      });
      this.mobileNo.nativeElement.focus();
      return false;
    }
    if (this.newSignUp.email) {
      if (!this.newSignUp.email.match(this.emailAddressPattern)) {
        this.toastr.warning('Please enter a valid email address', '', {
          positionClass: 'toast-top-right',
          closeButton: true,
        });
        this.email.nativeElement.focus();
        return false;
      }
    }
    if (!this.newSignUp.password) {
      this.toastr.warning('Please create your password', '', {
        positionClass: 'toast-top-right',
        closeButton: true,
      });
      this.password.nativeElement.focus();
      return false;
    }

    return true;
  }

  signUpInformation() {
    debugger;
    if (!this.isValidSignUpForm()) {
      return;
    }
    this.signUpRequestHeader.requestId = this.header.requestId;
    this.signUpRequestHeader.requestDateTime = this.header.requestDateTime;
    this.signUpRequestHeader.requestSource = this.header.requestSource;
    this.signUpRequestHeader.requestServiceSource =
      this.header.requestServiceSource;

    this.newSignUp = this.signUpForm.value;
    this.newSignUp.loginId = this.newSignUp.mobileNo;
    // this.newSignUp.userName = this.newSignUp.mobileNo;

    this.signUpRequest.body = this.newSignUp;
    this.signUpRequest.header = this.signUpRequestHeader;
    this._spinner.show();

    this._signUpService.saveSignUpInformation(this.signUpRequest).subscribe(
      (data) => {
        this._spinner.hide();

        if (data != null && data.header.responseCode === '200') {
          if (data.body.loginStatus == 'OK') {
            this._webPortalService.customerUserInfo.next(data.body);
            this.loginForm.controls.loginId.setValue(this.newSignUp.loginId);
            this.loginForm.controls.password.setValue(this.newSignUp.password);
            this.login();
          } else {
            this.toastr.warning('Unable To Sign Up', '', {
              positionClass: 'toast-top-right',
              closeButton: true,
            });
          }
        } else {
          if (data != null && data.header.responseCode === 'Duplicate') {
            this.showSignUpFailedMessgae = true;
            if (this.locale === 'en') {
              this.signUpFailedMessgae =
                'There is already a user with this mobile no ' +
                data.body.mobileNo +
                '. Please log in.';
            } else {
              this.signUpFailedMessgae =
                'এই মোবাইল নম্বর ' +
                this.constantService.convertEnglishToBangla(
                  data.body.mobileNo
                ) +
                ' এর একজন ব্যবহারকারী ইতিমধ্যেই আছে। অনুগ্রহ করে লগ ইন করুন';
            }
            setTimeout(() => {
              this.showSignUpFailedMessgae = false;
            }, 5000);
          } else {
            this.toastr.warning('Unable To Sign Up', '', {
              positionClass: 'toast-top-right',
              closeButton: true,
            });
          }
        }
      },
      (error) => {
        console.log(error);
        this._spinner.hide();
        this.toastr.error(error.message, '', {
          positionClass: 'toast-top-right',
          closeButton: true,
        });
      }
    );
  }

  goToCustomerSignUpPage() {
    this.showSignInPage = false;
    this.showSignUpPage = true;
    this.showForgotPasswordPage = false;
    this.showResetPasswordPage = false;
    this.isError = false;
  }

  goToCustomerLoginPage() {
    this.showSignInPage = true;
    this.showSignUpPage = false;
    this.showForgotPasswordPage = false;
    this.showResetPasswordPage = false;
    this.isError = false;
  }

  goToForgotPasswordPage() {
    this.showSignInPage = false;
    this.showSignUpPage = false;
    this.showForgotPasswordPage = true;
    this.forgotPasswordTitle = 'Find Your Account';
    this.showForgotPasswordLoginId = true;
    this.showForgotPasswordOtp = false;
    this.showResetPasswordPage = false;
    this.isError = false;
  }

  emailAvailablity(e: any) {
    //this.email = { email: e.target.value };
    //this.emailAvailable = true;
    /* this._authService.checkEmail(this.email).subscribe((res: Server_Response) => {
          this.emailAvailable = !res.data;
      })*/
  }

  ngOnInit() {
    this.locale = this.translate.currentLang;
    this.translate.onLangChange.subscribe((s: { lang: string }) => {
      if (s.lang === 'en') {
        this.locale = 'en';
      } else {
        this.locale = 'bn';
      }
    });
    this.isError = false;
    this.signInTitle = 'Sign In';
    this.forgotPasswordTitle = 'Find Your Account';
    this.showSignInPage = true;
    this.showSignUpPage = false;
    this.showForgotPasswordPage = false;
    this.showForgotPasswordLoginId = true;
    this.showForgotPasswordOtp = false;
    this.showResetPasswordPage = false;

    this.userInfo = JSON.parse(
      this._appStorageService.getData(LOGIN_LOCAL_STORAGE_KEY)
    );
    this.appSettingsInfo = JSON.parse(
      this._appStorageService.getData(APP_SETTINGS_LIST_LOCAL_STORAGE_KEY)
    );

    if (this.userInfo != null) {
      if (this.userInfo.roleType == 'Customer') {
        this.router.navigate(['customer/dashboard']);
      } else if (this.userInfo.roleType == 'Admin') {
        this.router.navigate(['admin/dashboard']);
      } else if (this.userInfo.roleType == 'Insurer') {
        this.router.navigate(['insurer/dashboard']);
      } else if (this.userInfo.roleType == ROLE_TYPE_REGISTERED_USER) {
        this.router.navigate(['registered-user/dashboard']);
      }
    }
    //this.authorizeApplication();

    this.signUpForm = this.formBuilder.group({
      fullName: ['', Validators.required],
      email: [
        '',
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ],
      mobileNo: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.forgotPasswordForm = this.formBuilder.group({
      loginId: ['', Validators.required],
      otp: [''],
    });

    this.resetPasswordForm = this.formBuilder.group({
      loginId: [''],
      newPassword: ['', Validators.required],
      confirmNewPassword: ['', Validators.required],
    });
  }

  ngOnDestroy() {}

  getAppSettingsList() {
    this.requestHeader.requestId = this.header.requestId;
    this.requestHeader.requestDateTime = this.header.requestDateTime;
    this.requestHeader.requestSource = this.header.requestSource;
    this.requestHeader.requestServiceSource = this.header.requestServiceSource;

    this.appSettingsListRequest.header = this.requestHeader;
    this.appSettingsListRequest.body = this.appSettingsListRequestBody;

    this._appSettingsService
      .getAppSettingsList(this.appSettingsListRequest)
      .subscribe((data) => {
        if (data.header.responseCode == '200') {
          this._appStorageService.setData(
            APP_SETTINGS_LIST_LOCAL_STORAGE_KEY,
            data.body.appSettingsList
          );
        }
      });
  }

  login() {
    this.requestHeader.requestId = this.header.requestId;
    this.requestHeader.requestDateTime = this.header.requestDateTime;
    this.requestHeader.requestSource = this.header.requestSource;
    this.requestHeader.requestServiceSource = this.header.requestServiceSource;

    this.signInData = this.loginForm.value;
    debugger;
    this.adminLogInRequest.body = this.signInData;
    this.adminLogInRequest.header = this.requestHeader;
    this._spinner.show();

    this.customerLogInService.doLogin(this.adminLogInRequest).subscribe(
      (data) => {
        this._spinner.hide();
        if (data.header.responseCode == '200') {
        } else {
        }

        if (data.body.loginStatus == 'OK') {
          this._appStorageService.setData(
            ADMIN_LOGIN_MENUS_LOCAL_STORAGE_KEY,
            data.body.accessJson
          );
          localStorage.setItem('loggedIn', 'true');
          this._appStorageService.setData(
            LOGIN_LOCAL_STORAGE_KEY,
            JSON.stringify(data.body)
          );

          localStorage.setItem('loggedIn', 'true');
          this._appStorageService.setData(
            ADMIN_LOGIN_LOCAL_STORAGE_KEY,
            JSON.stringify(data.body)
          );
          if (data.body.roleType == ROLE_TYPE_CUSTOMER) {
            localStorage.setItem(
              CUSTOMER_LOGIN_LOCAL_STORAGE_KEY,
              JSON.stringify(data.body)
            );
            this.router.navigate(['customer/dashboard']);
          } else if (data.body.roleType == ROLE_TYPE_INSURER) {
            this.router.navigate(['insurer/dashboard']);
          } else if (data.body.roleType == ROLE_TYPE_ADMIN) {
            this.router.navigate(['admin/dashboard']);
          } else if (data.body.roleType == ROLE_TYPE_REGISTERED_USER) {
            this.router.navigate(['registered-user/dashboard']);
          } else if (data.body.roleType == ROLE_TYPE_UNIT_PROMOTER) {
            this.router.navigate(['unit-promoter/dashboard']);
          }
          this.toastr.success('Successfully login', '', {
            positionClass: 'toast-top-right',
            closeButton: true,
          });
        } else {
          this.toastr.warning('Unable login', '', {
            positionClass: 'toast-top-right',
            closeButton: true,
          });
          this.isError = true;
          setTimeout(() => {
            this.isError = false;
          }, 5000);
        }
      },
      (error) => {
        this._spinner.hide();
        this.isError = true;
        console.log(error);
        setTimeout(() => {
          this.isError = false;
        }, 5000);
      }
    );
  }

  sendOtp() {
    this.forgotPasswordData = this.forgotPasswordForm.value;
    if (!this.forgotPasswordData.loginId) {
      this.toastr.warning('Please enter username/loginid', '', {
        positionClass: 'toast-top-right',
        closeButton: true,
      });
      this.loginId.nativeElement.focus();
      return false;
    }
    debugger;
    var isSendSms = 'No';
    if (this.appSettingsInfo.length > 0) {
      var smsSettingsObj = _.where(this.appSettingsInfo, {
        key: APP_SETTINGS_SMS,
      });
      if (smsSettingsObj.length > 0) {
        isSendSms = smsSettingsObj[0].value;
      }
    }
    if (isSendSms != 'Yes') {
      this.toastr.warning('otp sent failed. Pleas try later.', '', {
        positionClass: 'toast-top-right',
        closeButton: true,
      });
      return;
    }

    this.requestHeader.requestId = this.header.requestId;
    this.requestHeader.requestDateTime = this.header.requestDateTime;
    this.requestHeader.requestSource = this.header.requestSource;
    this.requestHeader.requestServiceSource = this.header.requestServiceSource;

    this.sendOtpRequest.header = this.requestHeader;
    this.sendOtpRequest.body = this.sendOtpRequestBody;
    this.sendOtpRequest.body.loginId = this.forgotPasswordData.loginId;

    this._spinner.show();
    this._otpService.sendOtp(this.sendOtpRequest).subscribe(
      (data) => {
        this._spinner.hide();
        if (data.header.responseCode == '200') {
          this.forgotPasswordTitle = 'Verify OTP';
          this.showForgotPasswordLoginId = false;
          this.showForgotPasswordOtp = true;
          this.toastr.success('Successfully sent otp.', '', {
            positionClass: 'toast-top-right',
            closeButton: true,
          });
        } else {
          this.toastr.warning('Unable to send otp.', '', {
            positionClass: 'toast-top-right',
            closeButton: true,
          });
        }
      },
      (error) => {
        console.log(error);
        this._spinner.hide();
        this.toastr.error(error.message, '', {
          positionClass: 'toast-top-right',
          closeButton: true,
        });
      }
    );
  }

  verifyOtp() {
    this.forgotPasswordData = this.forgotPasswordForm.value;
    if (!this.forgotPasswordData.otp) {
      this.toastr.warning('Please enter otp', '', {
        positionClass: 'toast-top-right',
        closeButton: true,
      });
      this.otp.nativeElement.focus();
      return false;
    }
    this.requestHeader.requestId = this.header.requestId;
    this.requestHeader.requestDateTime = this.header.requestDateTime;
    this.requestHeader.requestSource = this.header.requestSource;
    this.requestHeader.requestServiceSource = this.header.requestServiceSource;

    this.verifyOtpRequest.header = this.requestHeader;
    this.verifyOtpRequest.body = this.verifyOtpRequestBody;
    this.verifyOtpRequest.body.loginId = this.forgotPasswordData.loginId;
    this.verifyOtpRequest.body.otp = this.forgotPasswordData.otp;

    this._otpService.verifyOtp(this.verifyOtpRequest).subscribe(
      (data) => {
        if (data.header.responseCode == '200') {
          this.resetPasswordForm.patchValue({ loginId: data.body.loginId });
          this.showForgotPasswordPage = false;
          this.showResetPasswordPage = true;
          this.toastr.success('Successfully otp verified', '', {
            positionClass: 'toast-top-right',
            closeButton: true,
          });
        } else {
          this.toastr.warning('Unable to verify otp.', '', {
            positionClass: 'toast-top-right',
            closeButton: true,
          });
        }
      },
      (error) => {
        console.log(error);
        this._spinner.hide();
        this.toastr.error(error.message, '', {
          positionClass: 'toast-top-right',
          closeButton: true,
        });
      }
    );
  }

  resetPassword() {
    this.resetPasswordData = this.resetPasswordForm.value;
    if (!this.resetPasswordData.newPassword) {
      this.toastr.warning('Please enter new password', '', {
        positionClass: 'toast-top-right',
        closeButton: true,
      });
      this.otp.newPassword.focus();
      return false;
    }
    if (!this.resetPasswordData.confirmNewPassword) {
      this.toastr.warning('Please enter confirm new password', '', {
        positionClass: 'toast-top-right',
        closeButton: true,
      });
      this.otp.confirmNewPassword.focus();
      return false;
    }
    if (
      this.resetPasswordData.confirmNewPassword !=
      this.resetPasswordData.newPassword
    ) {
      this.toastr.warning('Passwords do not match.', '', {
        positionClass: 'toast-top-right',
        closeButton: true,
      });
      this.otp.confirmNewPassword.focus();
      return false;
    }

    this.requestHeader.requestId = this.header.requestId;
    this.requestHeader.requestDateTime = this.header.requestDateTime;
    this.requestHeader.requestSource = this.header.requestSource;
    this.requestHeader.requestServiceSource = this.header.requestServiceSource;

    this.resetPasswordRequest.header = this.requestHeader;
    this.resetPasswordRequest.body = this.resetPasswordRequestBody;
    this.resetPasswordRequest.body.loginId = this.resetPasswordData.loginId;
    this.resetPasswordRequest.body.newPassword =
      this.resetPasswordData.newPassword;
    this.resetPasswordRequest.body.confirmNewPassword =
      this.resetPasswordData.confirmNewPassword;

    this._passwordService.resetPassword(this.resetPasswordRequest).subscribe(
      (data) => {
        if (data.header.responseCode == '200') {
          this.showSignInPage = true;
          this.showResetPasswordPage = false;
          this.toastr.success('Successfully reset password', '', {
            positionClass: 'toast-top-right',
            closeButton: true,
          });
        } else {
          this.toastr.warning('Unable to reset password.', '', {
            positionClass: 'toast-top-right',
            closeButton: true,
          });
        }
      },
      (error) => {
        console.log(error);
        this._spinner.hide();
        this.toastr.error(error.message, '', {
          positionClass: 'toast-top-right',
          closeButton: true,
        });
      }
    );
  }

  authorizeApplication() {
    this.authenticationService
      .authorizeApplication(APPLICATION_NAME)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
        untilDestroyed(this)
      )
      .subscribe(
        (resData) => {
          this.token = resData.body.authenticationToken;
          this.authorizeClient();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  authorizeClient() {
    this.authenticationService
      .authorizeClient(this.token)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
        untilDestroyed(this)
      )
      .subscribe(
        (resData) => {
          this.authenticationToken = resData.body.authenticationToken;
          if (!this.userLocationInfo) {
            this.getLocationInfo();
          }
        },
        (error) => console.log(error)
      );
  }

  getLocationInfo() {
    this.authenticationService.getUserLocationInfo().subscribe(
      (resData) => {
        this.userLocationInfo = resData;
      },
      () => {}
    );
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      //username: ['', Validators.required],
      loginId: ['', Validators.required],
      password: ['', Validators.required],
      remember: true,
    });
  }
}
