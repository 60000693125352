import { RelativeType } from '@app/hoothut-application/common/shared/model/relative-type';

let beemaboxInitialPageLoad = 1;
function setBeemaboxInitialPageLoad(value: number) {
  beemaboxInitialPageLoad = value;
}
export { beemaboxInitialPageLoad, setBeemaboxInitialPageLoad };

export const AUTH_STORE_KEY = 'accesstoken.hoothut.cookie.store.key';
export const LOGIN_COOKIE_STORE_KEY = 'hoothut.login.cookie.store.key';
export const PLAN_INFORMATION_COOKIE_STORE_KEY =
  'hoothut.plan.information.cookie.store.key';

export const LOGIN_LOCAL_STORAGE_KEY = 'hoothut.login.local.storage.key';
export const CUSTOMER_LOGIN_LOCAL_STORAGE_KEY =
  'hoothut.customer.login.local.storage.key';
export const ADMIN_LOGIN_LOCAL_STORAGE_KEY =
  'hoothut.admin.login.local.storage.key';
export const ADMIN_LOGIN_MENUS_LOCAL_STORAGE_KEY =
  'hoothut.admin.login.menus.local.storage.key';
export const APP_SETTINGS_LIST_LOCAL_STORAGE_KEY =
  'hoothut.admin.app.settings.list.local.storage.key';

export const SELECTED_PRIVATE_MOTOR_PLAN_COMPARE_LOCAL_STORAGE_KEY =
  'hoothut.selected.privatemotor.plan.compare.local.storage.key';

export const SELECTED_PRIVATE_MOTOR_PLAN_LOCAL_STORAGE_KEY =
  'hoothut.selected.privatemotor.plan.local.storage.key';

export const SELECTED_COMMERCIAL_MOTOR_PLAN_LOCAL_STORAGE_KEY =
  'hoothut.selected.commercialmotor.plan.local.storage.key';

export const SELECTED_ENDOWMENT_PLAN_LOCAL_STORAGE_KEY =
  'hoothut.selected.endowment.plan.local.storage.key';
export const SELECTED_DENMOHOR_PLAN_LOCAL_STORAGE_KEY =
  'hoothut.selected.denmohor.plan.local.storage.key';
export const SELECTED_PENSION_PLAN_LOCAL_STORAGE_KEY =
  'hoothut.selected.pension.plan.local.storage.key';
export const SELECTED_HAJJ_PLAN_LOCAL_STORAGE_KEY =
  'hoothut.selected.hajj.plan.local.storage.key';
export const SELECTED_EDUCATION_PLAN_LOCAL_STORAGE_KEY =
  'hoothut.selected.education.plan.local.storage.key';
export const SELECTED_SINGLE_PREMIUM_PLAN_LOCAL_STORAGE_KEY =
  'hoothut.selected.single.premium.plan.local.storage.key';
export const SELECTED_MONEY_BACK_PLAN_LOCAL_STORAGE_KEY =
  'hoothut.selected.money.back.plan.local.storage.key';
export const SELECTED_CHILD_PLAN_LOCAL_STORAGE_KEY =
  'hoothut.selected.child.plan.local.storage.key';

export const PRIVATE_MOTOR_PLAN_PREVIEW_LOCAL_STORAGE_KEY =
  'hoothut.nonlifeplan.privatemotor.preview.local.storage.key';

export const PRIVATE_MOTOR_PLAN_PREVIEW_DATA_BACK_LOCAL_STORAGE_KEY =
  'hoothut.nonlifeplan.privatemotor.previewback.local.storage.key';

export const COMMERCIAL_MOTOR_PLAN_PREVIEW_DATA_BACK_LOCAL_STORAGE_KEY =
  'hoothut.nonlifeplan.commercialmotor.previewback.local.storage.key';

export const COMMERCIAL_MOTOR_PLAN_PREVIEW_LOCAL_STORAGE_KEY =
  'hoothut.nonlifeplan.commercialmotor.preview.local.storage.key';

export const JAMUNA_ENDOWMENT_PLAN_PREVIEW_LOCAL_STORAGE_KEY =
  'hoothut.jamuna.endowment.plan.preview.local.storage.key';
export const JAMUNA_HAJJ_PLAN_PREVIEW_LOCAL_STORAGE_KEY =
  'hoothut.jamuna.hajj.plan.preview.local.storage.key';
export const ENDOWMENT_PLAN_PREVIEW_LOCAL_STORAGE_KEY =
  'hoothut.endowment.plan.preview.local.storage.key';
export const PRIVATE_MOTOR_PLAN_CALCULATOR_QUOTE_DATA_LOCAL_STORAGE_KEY =
  'hoothut.privatemotor.plan.calculator.quote.data.local.storage.key';
export const COMMERCIAL_MOTOR_PLAN_CALCULATOR_QUOTE_DATA_LOCAL_STORAGE_KEY =
  'hoothut.commercialmotor.plan.calculator.quote.data.local.storage.key';
export const ENDOWMENT_PLAN_CALCULATOR_QUOTE_DATA_LOCAL_STORAGE_KEY =
  'hoothut.endowment.plan.calculator.quote.data.local.storage.key';
export const DENMOHOR_PLAN_CALCULATOR_QUOTE_DATA_LOCAL_STORAGE_KEY =
  'hoothut.denmohor.plan.calculator.quote.data.local.storage.key';
export const EDUCATION_PLAN_CALCULATOR_QUOTE_DATA_LOCAL_STORAGE_KEY =
  'hoothut.education.plan.calculator.quote.data.local.storage.key';
export const SINGLE_PREMIUM_PLAN_CALCULATOR_QUOTE_DATA_LOCAL_STORAGE_KEY =
  'hoothut.single.premium.plan.calculator.quote.data.local.storage.key';
export const MONEY_BACK_PLAN_CALCULATOR_QUOTE_DATA_LOCAL_STORAGE_KEY =
  'hoothut.money.back.plan.calculator.quote.data.local.storage.key';
export const CHILD_PLAN_CALCULATOR_QUOTE_DATA_LOCAL_STORAGE_KEY =
  'hoothut.child.plan.calculator.quote.data.local.storage.key';
export const HAJJ_PLAN_CALCULATOR_QUOTE_DATA_LOCAL_STORAGE_KEY =
  'hoothut.hajj.plan.calculator.quote.data.local.storage.key';

export const PENSION_PLAN_CALCULATOR_QUOTE_DATA_LOCAL_STORAGE_KEY =
  'hoothut.pension.plan.calculator.quote.data.local.storage.key';
export const PENSION_PLAN_COMPARE_DATA_LOCAL_STORAGE_KEY =
  'hoothut.pension.plan.compare.data.local.storage.key';
export const PENSION_PLAN_COMPARE_TOTAL_DATA_LOCAL_STORAGE_KEY =
  'hoothut.pension.plan.compare.total.data.local.storage.key';

export const CLIENT_ID = '5C:51:4F:E7:72:8C';
export const APPLICATION_NAME = 'brownfield-admin-0';
export const CLIENT_NAME = 'hoothut';
export const REQUEST_SOURCE = 'hoothut';
export const REQUEST_SERVICE_SOURCE = 'hoothut-portal';
export const REQUEST_SMS_SERVICE_SOURCE = 'hoothut-portal-send-sms';
export const REQUEST_VERSION = '1.0';
export const REQUEST_TIMEOUT_IN_SECONDS = 120;
export const maximumAge = 70;
export const notificationDelay: number = 5000;
export const mobileNoRegEx = '^(0|[0+]880)1[3-9][0-9]{8}$';
export const emailRegEx = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
export const nidNoRegEx = '^([0-9]{10}|[0-9]{13}|[0-9]{17})$';
export const passportNoRegEx = '^([0-9]{7})$';
export const birthDrivingNoRegEx = '^([0-9]{8,35})$';
export const nidMaxlength: number = 17;

export const DEFAULT_BONUS_PCT = 3.92;
export const DEFAULT_IMAGE_SIZE_IN_KB = 200;
export const DEFAULT_APP_LANGUAGE = 'en';
export const ROLE_TYPE_INSURER = 'Insurer';
export const ROLE_TYPE_ADMIN = 'Admin';
export const ROLE_TYPE_CUSTOMER = 'Customer';
export const ROLE_TYPE_REGISTERED_USER = 'Registered User';
export const ROLE_TYPE_UNIT_PROMOTER = 'Unit Promoter';
export const INSURER_TYPE_NON_LIFE = 'Non-life';
export const DATABASE_DATE_FORMAT = 'YYYY-MM-DD';
export const UI_FORM_DATE_FORMAT = 'YYYY-MMM-DD';
export const VIEW_FULL_DATE_FORMAT = 'Do MMMM, YYYY';
export const KG_TO_POUND = 2.20462;
export const POUND_TO_KG = 0.453592;
export const FEET_TO_METER = 0.3048;
export const FEET_TO_INCHES = 12;
export const FEET_TO_CENTIMETER = 30.48;
export const INCHES_TO_METER = 0.0254;
export const INCHES_TO_CENTIMETER = 2.54;
export const CENTIMETER_TO_METER = 0.01;
export const METER_TO_CENTIMETER = 100;
export const CENTIMETER_TO_FEET = 0.0328084;
export const CENTIMETER_TO_INCHES = 0.393701;
export const FEET_LIST: Number[] = [4, 5, 6, 7];
export const INCH_LIST: Number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
// export const RELATIVE_TYPE_LIST: RelativeType[] = [
//     { key: 'Brother', relativeTypeEn: 'Brother', relativeTypeBn: 'ভাই' },
//     { key: 'Sister', relativeTypeEn: 'Sister', relativeTypeBn: 'বোন' }
// ];

export const ONE_KG_TO_POUND = 2.20462;
export const ONE_POUND_TO_KG = 0.453592;
export const ONE_FEET_TO_METER = 0.3048;
export const ONE_FEET_TO_INCH = 12;
export const ONE_FEET_TO_CENTIMETER = 30.48;
export const ONE_INCH_TO_METER = 0.0254;
export const ONE_INCH_TO_CENTIMETER = 2.54;
export const ONE_CENTIMETER_TO_METER = 0.01;

export const TODAY_DATE = new Date();
export const STATUS_DRAFT = 'Draft';
export const STATUS_SUBMITTED = 'Submitted';
export const STATUS_APPROVED = 'Approved';
export const STATUS_REJECTED = 'Rejected';
export const STATUS_PENDING = 'Pending';
export const STATUS_CREATED = 'Created';
export const STATUS_ACCEPTED = 'Accepted';
export const STATUS_CANCEL = 'Cancel';
export const STATUS_PAID = 'Paid';
export const STATUS_CHECKED = 'Checked';
export const STATUS_REJECT = 'Reject';
export const APP_SETTINGS_SMS = 'sms';
export const APP_SETTINGS_EMAIL = 'email';
export const CHANGE_ROLE_REGISTERED_USER_TO_CUSTOMER =
  'RegisteredUserToCustomer';
export const UPDATE_USER_INFO = 'UpdateUserInfo';
export const NO_DATA_FOUND = 'NoDataFound';
export const COMPONENT_APPLICATION_FORM = 'ApplicationForm';
export const COMPONENT_APPLICATION_EDIT = 'ApplicationEdit';

export const OTP_FEATURS_APPLICATION = 'Application';
export const OTP_FEATURS_OFFER = 'Offer';
export const OTP_FEATURS_FORGET_PASSWORD = 'Forgot Password';

export const OTP_NOTES_APPLICATION = 'OTP sent for application verification.';

// COMMON SERVICE API NAME
export const SECURITY_LOGIN_API_V1 = 'security/v1/login';
export const SECURITY_GET_USERINFO_BY_LOGIN_ID_API_V1 =
  'security/v1/get-userinfo-by-loginid';
export const COMMON_DASHBOARD_ADMIN_API_V1 =
  'life-dashboard/v1/dashboard-admin';
export const COMMON_DASHBOARD_INSURER_API_V1 =
  'life-dashboard/v1/dashboard-insurer';
export const COMMON_DASHBOARD_CUSTOMER_API_V1 =
  'life-dashboard/v1/dashboard-customer';
export const COMMON_DASHBOARD_REGISTERED_USER_API_V1 =
  'life-dashboard/v1/dashboard-registered-user';
export const COMMON_DASHBOARD_UNIT_PROMOTER_API_V1 =
  'life-dashboard/v1/dashboard-unit-promoter';
export const COMMON_DASHBOARD_APPLICATION_BY_WEEKLY_API_V1 =
  'life-dashboard/v1/dashboard-application-by-weekly';
export const COMMON_DASHBOARD_SUMMARY_BY_WEEKLY_API_V1 =
  'life-dashboard/v1/dashboard-summary-by-weekly';
export const COMMON_DASHBOARD_OFFER_BY_WEEKLY_API_V1 =
  'life-dashboard/v1/dashboard-offer-by-weekly';
export const COMMON_DASHBOARD_PAYMENT_BY_WEEKLY_API_V1 =
  'life-dashboard/v1/dashboard-payment-by-weekly';
export const COMMON_DASHBOARD_POLICY_BY_WEEKLY_API_V1 =
  'life-dashboard/v1/dashboard-policy-by-weekly';
export const COMMON_DASHBOARD_APPLICATION_BY_YEARLY_API_V1 =
  'life-dashboard/v1/dashboard-application-by-yearly';
export const COMMON_DASHBOARD_SUMMARY_BY_YEARLY_API_V1 =
  'life-dashboard/v1/dashboard-summary-by-yearly';
export const COMMON_DASHBOARD_PREMIUM_AMOUNT_BY_MONTHLY_API_V1 =
  'life-dashboard/v1/dashboard-premium-amount-by-monthly';
export const COMMON_DASHBOARD_PREMIUM_AMOUNT_BY_WEEKLY_API_V1 =
  'life-dashboard/v1/dashboard-premium-amount-by-weekly';
export const COMMON_DASHBOARD_PREMIUM_AMOUNT_BY_YEARLY_API_V1 =
  'life-dashboard/v1/dashboard-premium-amount-by-yearly';
export const COMMON_DASHBOARD_OFFER_BY_YEARLY_API_V1 =
  'life-dashboard/v1/dashboard-offer-by-yearly';
export const COMMON_DASHBOARD_PAYMENT_BY_YEARLY_API_V1 =
  'life-dashboard/v1/dashboard-payment-by-yearly';
export const COMMON_DASHBOARD_POLICY_BY_YEARLY_API_V1 =
  'life-dashboard/v1/dashboard-policy-by-yearly';
export const GET_INSURER_LIST_API_V1 = 'life-insurer/v1/get-insurer-list';
export const GET_INSURER_BY_ID_API_V1 = 'life-insurer/v1/get-insurer-by-id';
export const COMMON_GET_CUSTOMER_LIFE_OFFER_LIST_API_V1 =
  'life-offer/v1/get-customer-offer-list';
export const COMMON_GET_CUSTOMER_PENDING_LIFE_OFFER_LIST_API_V1 =
  'life-offer/v1/get-customer-pending-offer-list';
export const COMMON_GET_CUSTOMER_REJECTED_OFFER_LIST_API_V1 =
  'life-offer/v1/get-customer-rejected-offer-list';
// Customer
export const COMMON_GET_CUSTOMER_BY_ID_API_V1 =
  'hoothut-customer/v1/get-customer-by-id';
export const COMMON_GET_CUSTOMER_BY_SEARCH_TEXT_API_V1 =
  'hoothut-customer/v1/get-customer-by-search-text';
export const COMMON_CHECK_AND_CREATE_CUSTOMER_API_V1 =
  'hoothut-customer/v1/check-and-create-customer';
export const COMMON_SAVE_CUSTOMER_API_V1 = 'hoothut-customer/v1/save-customer';
export const COMMON_UPDATE_CUSTOMER_API_V1 =
  'hoothut-customer/v1/update-customer';
export const COMMON_GET_ADMIN_CUSTOMER_LIST_API_V1 =
  'hoothut-customer/v1/get-admin-customer-list';
export const COMMON_GET_INSURER_CUSTOMER_LIST_API_V1 =
  'hoothut-customer/v1/get-insurer-customer-list';
export const COMMON_GET_UNIT_PROMOTER_CUSTOMER_LIST_API_V1 =
  'hoothut-customer/v1/get-unit-promoter-customer-list';

// HootHut NID Related API
export const COMMON_GET_REAL_TIME_NID_INFO_API_V1 =
  'hoothut-nid/v1/get-real-time-nid-info';

// Cluster
export const COMMON_GET_CLUSTER_BY_ID_API_V1 =
  'hoothut-cluster/v1/get-cluster-by-id';
export const COMMON_SAVE_CLUSTER_API_V1 = 'hoothut-cluster/v1/save-cluster';
export const COMMON_UPDATE_CLUSTER_API_V1 = 'hoothut-cluster/v1/update-cluster';
export const COMMON_GET_CLUSTER_LIST_API_V1 =
  'hoothut-cluster/v1/get-cluster-list';
export const COMMON_CHECK_LOGINID_API_V1 = 'security/v1/check-loginid';

// Unit Promoter
export const COMMON_UPDATE_UNIT_PROMOTER_API_V1 =
  'unit-promoter/v1/update-unit-promoter';
export const COMMON_SAVE_UNIT_PROMOTER_API_V1 =
  'unit-promoter/v1/save-unit-promoter';
export const COMMON_GET_UNIT_PROMOTER_BY_ID_API_V1 =
  'unit-promoter/v1/get-unit-promoter-by-id';
export const COMMON_GET_UNIT_PROMOTER_LIST_API_V1 =
  'unit-promoter/v1/get-unit-promoter-list';

// Quote Request
export const COMMON_UPDATE_QUOTE_REQUEST_API_V1 =
  'life-quote-request/v1/update-quote-request';
export const COMMON_SAVE_QUOTE_REQUEST_API_V1 =
  'life-quote-request/v1/save-quote-request';
export const COMMON_GET_QUOTE_REQUEST_BY_ID_API_V1 =
  'life-quote-request/v1/get-quote-request-by-id';
export const COMMON_GET_QUOTE_REQUEST_LIST_API_V1 =
  'life-quote-request/v1/get-quote-request-list';

// OTP Request
export const COMMON_SEND_OTP_BY_LOGIN_ID_API_V1 =
  'hoothut-otp/v1/send-otp-by-loginid';
export const COMMON_RESEND_OTP_BY_LOGIN_ID_API_V1 =
  'hoothut-otp/v1/resend-otp-by-loginid';
export const COMMON_VERIFY_OTP_BY_LOGIN_ID_API_V1 =
  'hoothut-otp/v1/verify-otp-by-loginid';
export const COMMON_SEND_OTP_BY_MOBILE_API_V1 =
  'hoothut-otp/v1/send-otp-by-mobile';
export const COMMON_RESEND_OTP_BY_MOBILE_API_V1 =
  'hoothut-otp/v1/resend-otp-by-mobile';
export const COMMON_VERIFY_OTP_BY_MOBILE_API_V1 =
  'hoothut-otp/v1/verify-otp-by-mobile';

// Life Quote related API
export const COMMON_UPDATE_QUOTE_API_V1 = 'life-quote/v1/update-quote';
export const COMMON_SAVE_QUOTE_API_V1 = 'life-quote/v1/save-quote';
export const COMMON_GET_QUOTE_BY_ID_API_V1 = 'life-quote/v1/get-quote-by-id';
export const COMMON_GET_QUOTE_LIST_API_V1 = 'life-quote/v1/get-quote-list';

// Non-Life Quote related API
export const COMMON_SAVE_NON_LIFE_QUOTE_API_V1 = 'nonlife-quote/v1/save-quote';
export const COMMON_GET_NON_LIFE_QUOTE_LIST_API_V1 =
  'nonlife-quote/v1/get-quote-list';

// common-payment
export const HOOTHUT_COMMON_GET_PAYMENT_LIST_API_V1 =
  'hoothut-payment/v1/get-payment-list';
export const HOOTHUT_COMMON_GET_PENDING_PAYMENT_LIST_API_V1 =
  'hoothut-payment/v1/get-pending-payment-list';
export const HOOTHUT_COMMON_GET_REJECTED_PAYMENT_LIST_API_V1 =
  'hoothut-payment/v1/get-rejected-payment-list';

export const HOOTHUT_COMMON_GET_CUSTOMER_PAYMENT_LIST_API_V1 =
  'hoothut-payment/v1/get-customer-payment-list';
export const HOOTHUT_COMMON_GET_CUSTOMER_PENDING_PAYMENT_LIST_API_V1 =
  'hoothut-payment/v1/get-customer-pending-payment-list';
export const HOOTHUT_COMMON_CUSTOMER_GET_REJECTED_PAYMENT_LIST_API_V1 =
  'hoothut-payment/v1/get-customer-rejected-payment-list';
export const HOOTHUT_COMMON_CUSTOMER_GET_UNCHECKED_PAYMENT_LIST_API_V1 =
  'hoothut-payment/v1/get-customer-unchecked-payment-list';

// Payment
// export const COMMON_GET_PAYMENT_LIST_API_V1 =
//   'life-payment/v1/get-payment-list';
// export const COMMON_GET_PENDING_PAYMENT_LIST_API_V1 =
//   'life-payment/v1/get-pending-payment-list';
// export const COMMON_GET_REJECTED_PAYMENT_LIST_API_V1 =
//   'life-payment/v1/get-rejected-payment-list';

// export const COMMON_GET_CUSTOMER_PAYMENT_LIST_API_V1 =
//   'life-payment/v1/get-customer-payment-list';
// export const COMMON_GET_CUSTOMER_PENDING_PAYMENT_LIST_API_V1 =
//   'life-payment/v1/get-customer-pending-payment-list';
// export const COMMON_CUSTOMER_GET_REJECTED_PAYMENT_LIST_API_V1 =
//   'life-payment/v1/get-customer-rejected-payment-list';
// export const COMMON_CUSTOMER_GET_UNCHECKED_PAYMENT_LIST_API_V1 =
//   'life-payment/v1/get-customer-unchecked-payment-list';

export const COMMON_CUSTOMER_GET_POLICY_LIST_API_V1 =
  'life-policy/v1/get-customer-policy-list';
export const COMMON_INSURER_GET_POLICY_LIST_API_V1 =
  'life-policy/v1/get-insurer-policy-list';
export const COMMON_INSURER_GET_PENDING_POLICY_LIST_API_V1 =
  'life-policy/v1/get-insurer-pending-policy-list';

export const COMMON_ADMIN_GET_POLICY_LIST_API_V1 =
  'life-policy/v1/get-admin-policy-list';
export const COMMON_ADMIN_GET_PENDING_POLICY_LIST_API_V1 =
  'life-policy/v1/get-admin-pending-policy-list';

// Non-Life Policy API
export const COMMON_NONLIFE_CUSTOMER_GET_POLICY_LIST_API_V1 =
  'nonlife-policy/v1/get-customer-policy-list';
export const COMMON_NONLIFE_INSURER_GET_POLICY_LIST_API_V1 =
  'nonlife-policy/v1/get-insurer-policy-list';
export const COMMON_NONLIFE_INSURER_GET_PENDING_POLICY_LIST_API_V1 =
  'nonlife-policy/v1/get-insurer-pending-policy-list';

export const COMMON_NONLIFE_ADMIN_GET_POLICY_LIST_API_V1 =
  'nonlife-policy/v1/get-admin-policy-list';
export const COMMON_NONLIFE_ADMIN_GET_PENDING_POLICY_LIST_API_V1 =
  'nonlife-policy/v1/get-admin-pending-policy-list';

export const COMMON_GET_OFFER_LIST_API_V1 = 'life-offer/v1/get-offer-list';
export const COMMON_GET_PENDING_OFFER_LIST_API_V1 =
  'life-offer/v1/get-pending-offer-list';
export const COMMON_GET_REJECTED_LIFE_OFFER_LIST_API_V1 =
  'life-offer/v1/get-rejected-offer-list';
// nonlife offer list
export const COMMON_GET_NONLIFE_OFFER_LIST_API_V1 =
  'nonlife-offer/v1/get-offer-list';
export const COMMON_GET_NONLIFE_PENDING_OFFER_LIST_API_V1 =
  'nonlife-offer/v1/get-pending-offer-list';
export const COMMON_GET_NONLIFE_REJECTED_OFFER_LIST_API_V1 =
  'nonlife-offer/v1/get-rejected-offer-list';
export const COMMON_GET_CUSTOMER_NONLIFE_OFFER_LIST_API_V1 =
  'nonlife-offer/v1/get-customer-offer-list';
export const COMMON_GET_CUSTOMER_NONLIFE_PENDING_OFFER_LIST_API_V1 =
  'nonlife-offer/v1/get-customer-pending-offer-list';
export const COMMON_GET_CUSTOMER_NONLIFE_REJECTED_OFFER_LIST_API_V1 =
  'nonlife-offer/v1/get-customer-rejected-offer-list';

export const COMMON_SEND_OTP_API_V1 = 'security/v1/send-otp';
export const COMMON_RESEND_OTP_API_V1 = 'security/v1/resend-otp';
export const COMMON_VERIFY_OTP_API_V1 = 'security/v1/verify-otp';
export const COMMON_RESET_PASSWORD_API_V1 = 'security/v1/reset-password';

//export const INSURANCE_SEEKER_REGISTRATION_API_V1 = 'v1/insurance/seeker/registration/save';
export const SECURITY_V1_REGISTRATION_API = 'security/v1/registration';
export const SECURITY_V1_VERIFY_SIGN_UP_API = 'security/v1/verify-sign-up';
// export const GET_QUOTATION_LIST_API_V1 = 'v1/quotation/getquotationlist';
export const LIFE_GET_QUOTATION_API_V1 = 'life-quotation/v1/get-quotation';

export const COMMON_GET_PLAN_FEATURES_BY_PLAN_ID =
  'life-quotation/v1/get-plan-features-by-plan-id';
export const COMMON_GET_PLAN_BENEFIT_BY_PLAN_ID =
  'life-quotation/v1/get-plan-benefit-by-plan-id';

export const LIFEPLAN_APPLICATION_SAVE_API_V1 =
  'v1/lifeplan/buy/application/save';
export const LIFEPLAN_UPDATE_APPLICATION_API_V1 =
  'v1/lifeplan/buy/application/update';
// Need to be removed
export const LIFEPLAN_APPLICATION_GET_BY_OID_API_V1 =
  'v1/lifeplan/buy/application/getbyoid';
export const LIFEPLAN_APPLICATION_GET_BY_TRACKING_ID_API_V1 =
  'life-application/v1/tem-endowment-get-application-by-id';
// Need to be removed
export const LIFEPLAN_APPLICATION_APPROVE_API_V1 =
  'v1/lifeplan/buy/application/approveorreject';
export const LIFEPLAN_APPLICATION_UPDATE_API_V1 =
  'life-application/v1/tem-endowment-update-application';
export const APPROVE_APPLICATION_API_V1 =
  'life/application/v1/approve-application';
export const REJECT_APPLICATION_API_V1 = 'v1/lifeplan/buy/application/reject';
// Base
export const COMMON_GET_APP_SETTINGS_LIST_API_V1 =
  'base/v1/get-app-settings-list';
export const COMMON_UPDATE_APP_SETTINGS_LIST_API_V1 =
  'base/v1/update-app-settings-list';
// Application
export const LIFEPLAN_APPLICATION_LIST_API_V1 =
  'life-application/v1/get-application-list';
export const COMMON_GET_APPLICATION_LIST_API_V1 =
  'life-application/v1/get-application-list';
export const COMMON_GET_DRAFT_APPLICATION_LIST_API_V1 =
  'life-application/v1/get-draft-application-list';
export const COMMON_GET_APPLICATION_BY_ID_API_V1 =
  'life-application/v1/get-application-by-id';
export const LIFEPLAN_TEM_HAJJ_APPLICATION_GET_BY_TRACKING_ID_API_V1 =
  'life-application/v1/tem-hajj-get-application-by-id';
export const LIFEPLAN_TEM_DENMOHOR_APPLICATION_GET_BY_TRACKING_ID_API_V1 =
  'life-application/v1/tem-denmohor-get-application-by-id';

//Offer
export const POLICY_OFFER_SAVE_API_V1 = 'v1/policy/offer/save';
export const POLICY_OFFER_ACCEPT_API_V1 = 'v1/policy/offer/acceptorreject';
export const POLICY_OFFER_REJECT_API_V1 = 'v1/policy/offer/reject';
export const GET_POLICY_OFFER_BY_OID_API_V1 = 'v1/policy/offer/getbyoid';
export const POLICY_OFFER_LIST_API_V1 = 'v1/policy/offer/list';
export const CUSTOMER_POLICY_OFFER_LIST_API_V1 =
  'v1/customer/policy/offer/list';
export const GET_PENDING_OFFER_LIST_API_V1 =
  'life/offer/v1/get-pending-offer-list';
export const GET_REJECTED_OFFER_LIST_API_V1 =
  'life/offer/v1/get-rejected-offer-list';
export const GET_CUSTOMER_PENDING_OFFER_LIST_API_V1 =
  'life/offer/v1/get-customer-pending-offer-list';

export const POLICY_OFFER_PAYMENT_LIST_API_V1 = 'v1/policy/offer/payment/list';
export const CUSTOMER_POLICY_OFFER_PAYMENT_LIST_API_V1 =
  'v1/customer/policy/offer/payment/list';
export const POLICY_OFFER_PAYMENT_SAVE_API_V1 = 'v1/policy/offer/payment/save';
export const POLICY_OFFER_PAYMENT_CHECKED_API_V1 =
  'v1/policy/offer/payment/checked';
export const POLICY_OFFER_PAYMENT_REJECT_API_V1 =
  'v1/policy/offer/payment/reject';
export const POLICY_OFFER_ACCEPT_OR_REJECT_API_V1 =
  'v1/policy/offer/acceptorreject';
export const GET_POLICY_PAYMENT_BY_OID_API_V1 = 'v1/policy/payment/getbyoid';
export const GET_PENDING_PAYMENT_LIST_API_V1 =
  'life/payment/v1/get-pending-payment-list';
export const GET_UNCHECKED_PAYMENT_LIST_API_V1 =
  'life/payment/v1/get-unchecked-payment-list';
export const GET_REJECTED_PAYMENT_LIST_API_V1 =
  'life/payment/v1/get-rejected-payment-list';

export const POLICY_GENERATE_POLICY_API_V1 = 'v1/policy/generate/policy';
export const GET_POLICY_BY_OID_API_V1 = 'v1/policy/getbyoid';
export const CUSTOMER_POLICY_LIST_API_V1 = 'v1/policy/list/customer';
export const INSURER_POLICY_LIST_API_V1 = 'v1/policy/list/insurer';
export const ADMIN_POLICY_LIST_API_V1 = 'v1/policy/list/admin';

export const GET_INSURER_PENDING_POLICY_LIST_API_V1 =
  'life/policy/v1/get-insurer-pending-policy-list';
export const GET_ADMIN_PENDING_POLICY_LIST_API_V1 =
  'life/policy/v1/get-admin-pending-policy-list';

// New

export const GET_DRAFT_APPLICATION_LIST_API_V1 =
  'life/application/v1/get-draft-application-list';
export const GET_SUBMITTED_APPLICATION_LIST_API =
  'v1/lifeplan/get/submitted/application/list';
export const GET_CUSTOMER_REJECTED_OFFER_LIST_API_V1 =
  'life/offer/v1/get-customer-rejected-offer-list';
export const GET_CUSTOMER_REJECTED_PAYMENT_LIST_API_V1 =
  'life/payment/v1/get-customer-rejected-payment-list';
export const GET_CUSTOMER_UNCHECKED_PAYMENT_LIST_API_V1 =
  'life/payment/v1/get-customer-unchecked-payment-list';
export const LIFE_CALCULATOR_V1_GET_ENDOWMENT_QUOTE_DATA =
  'life-calculator/v1/get-endowment-quote-data';
export const COMMON_LIFE_CALCULATOR_V1_GET_DENMOHOR_QUOTE_DATA =
  'life-calculator/v1/get-denmohor-quote-data';
export const COMMON_LIFE_CALCULATOR_GET_EDUCATION_QUOTE_DATA =
  'life-calculator/v1/get-education-quote-data';
export const GET_SINGLE_PREMIUM_QUOTE_DATA_V1 =
  'life-calculator/v1/get-single-premium-quote-data';
export const GET_MONEY_BACK_QUOTE_DATA_V1 =
  'life-calculator/v1/get-money-back-quote-data';
export const COMMON_LIFE_CALCULATOR_GET_HAJJ_QUOTE_DATA =
  'life-calculator/v1/get-hajj-quote-data';
export const COMMON_LIFE_CALCULATOR_GET_CHILD_QUOTE_DATA =
  'life-calculator/v1/get-child-quote-data';
export const GET_ENDOWMENT_PLAN_DETAIL_BY_OID_V1 =
  'life/quotation/v1/get-endowment-quotation-by-oid';
export const GET_QUOTE_DATA_PENSION_V1 =
  'life/calculator/v1/get-quote-data-pension';
export const GET_PENSION_QUOTATION_BY_OID_V1 =
  'life/quotation/v1/get-quotation-by-oid';
export const GET_MALE_QUOTE_DATA_PENSION_V1 =
  'life/calculator/v1/get-male-pension-quote-data-v1';
export const GET_FEMALE_QUOTE_DATA_PENSION_V1 =
  'life/calculator/v1/get-female-pension-quote-data-v1';
export const GET_PENSION_QUOTATION_LIST_API_V1 =
  'life/quotation/v1/get-pension-quotation-list';
export const GET_HAJJ_QUOTE_DATA_API_V1 =
  'life/calculator/v1/get-hajj-quote-data-v1';
// Occupation Class Type Premium Calculator
export const GET_OCCUPATION_CLASS_TYPE_LIST_API_V1 =
  'life-calculator/v1/get-occupation-class-type-list';
export const GET_OCCUPATION_CLASS_TYPE_PREMIUM_API_V1 =
  'life-calculator/v1/get-occupation-class-type-premium';

//export const FILE_UPLOAD = 'v1/file/service/upload';
//export const FILE_DOWNLOAD = 'v1/file/service/download';
export const FILE_UPLOAD = 'base/v1/file-upload';
export const FILE_DOWNLOAD = 'base/v1/file-download';
export const FILE_LIST = 'base/v1/file-list';

// added by kamal parvez
export const COMMON_GET_HAJJ_QUOTE_DATA =
  'life-calculator/v1/get-hajj-quote-data';
export const COMMON_GET_ENDOWMENT_QUOTE_DATA =
  'life-calculator/v1/get-endowment-quote-data';
export const COMMON_GET_PENSION_QUOTE_DATA =
  'life-calculator/v1/get-pension-quote-data';
export const COMMON_GET_MALE_PENSION_QUOTE_DATA =
  'life-calculator/v1/get-pension-male-quote-data';
export const COMMON_GET_FEMALE_PENSION_QUOTE_DATA =
  'life-calculator/v1/get-pension-female-quote-data';

export function isNullOrEmpty(value: any) {
  return (
    value === null ||
    value === '' ||
    value === 'null' ||
    value === 'undefined' ||
    value === undefined
  );
}

export function isUndefinedOrNull(value: any) {
  return value === null || value === 'undefined' || value === undefined;
}

export function convertCurrency(value: any) {
  if (isNullOrEmpty(value)) {
    return '0';
  }
  var str = Number(value).toFixed(0).toString();
  var digit = '';
  if (str.indexOf('.') > -1) {
    digit = str.substring(str.length - 3, str.length);
    str = str.substring(0, str.length - 3);
  }
  if (str.length > 3) {
    var s = str.substring(0, str.length - 3);
    s = s.replace(/\B(?=(\d{2})+(?!\d))/g, ',');
    str = s + ',' + str.substring(str.length - 3, str.length);
  }
  return str + digit;
}

export function convertDecimalCurrency(value: any, decimal: number) {
  if (isNullOrEmpty(value)) {
    return '0';
  }
  var str = Number(value).toFixed(decimal).toString();
  var digit = '';
  if (str.indexOf('.') > -1) {
    digit = str.substring(str.length - 3, str.length);
    str = str.substring(0, str.length - 3);
  }
  if (str.length > 3) {
    var s = str.substring(0, str.length - 3);
    s = s.replace(/\B(?=(\d{2})+(?!\d))/g, ',');
    str = s + ',' + str.substring(str.length - 3, str.length);
  }
  return str + digit;
}

// LOCAL STORAGE KEY
export const COMPARE_WEB_VIEW_PRIVATE_MOTOR_PLAN_LIST_DATA_LOCAL_STORAGE_KEY =
  'hoothut.web.compare.view.privatemotor.plan.list.data.local.storage.key';
export const COMPARE_WEB_VIEW_COMMERCIAL_MOTOR_PLAN_LIST_DATA_LOCAL_STORAGE_KEY =
  'hoothut.web.compare.view.commercialmotor.plan.list.data.local.storage.key';

export const COMPARE_WEB_VIEW_ENDOWMENT_PLAN_LIST_DATA_LOCAL_STORAGE_KEY =
  'hoothut.web.compare.view.endowment.plan.list.data.local.storage.key';
export const COMPARE_WEB_VIEW_DENMOHOR_PLAN_LIST_DATA_LOCAL_STORAGE_KEY =
  'hoothut.web.compare.view.denmohor.plan.list.data.local.storage.key';
export const COMPARE_WEB_VIEW_PENSION_PLAN_LIST_DATA_LOCAL_STORAGE_KEY =
  'hoothut.web.compare.view.pension.plan.list.data.local.storage.key';
export const COMPARE_WEB_VIEW_HAJJ_PLAN_LIST_DATA_LOCAL_STORAGE_KEY =
  'hoothut.web.compare.view.hajj.plan.list.data.local.storage.key';
export const COMPARE_WEB_VIEW_EDUCATION_PLAN_LIST_DATA_LOCAL_STORAGE_KEY =
  'hoothut.web.compare.view.education.plan.list.data.local.storage.key';
export const COMPARE_WEB_VIEW_SINGLE_PREMIUM_PLAN_LIST_DATA_LOCAL_STORAGE_KEY =
  'hoothut.web.compare.view.single.premium.plan.list.data.local.storage.key';
export const COMPARE_WEB_VIEW_MONEY_BACK_PLAN_LIST_DATA_LOCAL_STORAGE_KEY =
  'hoothut.web.compare.view.money.back.plan.list.data.local.storage.key';
export const COMPARE_WEB_VIEW_CHILD_PLAN_LIST_DATA_LOCAL_STORAGE_KEY =
  'hoothut.web.compare.view.child.plan.list.data.local.storage.key';
//export const BASE_API_URL = 'http://localhost:8080/hoothut/server/api';

// Non-Life-Motor Calculator
export const GET_VEHICLE_MANUFACTURER_LIST_API_V1 =
  'nonlife-calculator/v1/get-vehicle-manufacturer-list';
export const GET_VEHICLE_MODEL_LIST_API_V1 =
  'nonlife-calculator/v1/get-vehicle-model-list';
export const GET_VEHICLE_MODEL_VARIANT_LIST_API_V1 =
  'nonlife-calculator/v1/get-vehicle-model-variant-list';
export const GET_VEHICLE_MODEL_DETAIL_LIST_API_V1 =
  'nonlife-calculator/v1/get-vehicle-model-detail-list';
export const GET_VEHICLE_MODEL_DETAIL_LIST_BY_MODEL_ID_API_V1 =
  'nonlife-calculator/v1/get-vehicle-model-detail-list-by-model-id';

// Non Life Motor Quotation
export const NON_LIFE_GET_PRIVATE_MOTOR_QUOTATION_API_V1 =
  'nonlife-quotation/v1/get-private-motor-quotation';
export const NON_LIFE_GET_COMMERCIAL_MOTOR_QUOTATION_API_V1 =
  'nonlife-quotation/v1/get-commercial-motor-quotation';

export const NON_LIFE_GET_PRIVATE_MOTOR_QUOTATION_BY_INSURER_API_V1 =
  'nonlife-quotation/v1/get-private-motor-quotation-by-insurer';

// NON-LIFE APPLICATION LIST
export const COMMON_GET_NON_LIFE_APPLICATION_LIST_API_V1 =
  'nonlife-application/v1/get-application-list';
export const COMMON_GET_NON_LIFE_APPLICATION_BY_ID_API_V1 =
  'nonlife-application/v1/get-application-by-id';

// SMS SERVICE API
export const SEND_SMS_SERVICE_API_URL =
  'https://sms.celloscope.net/app/drws/messenger/message/v1/send-sms';

//Kamal Vai Base API Url
// export const BASE_API_URL = 'http://192.168.10.225:8080/beemabox/common';
// export const BASE_API_URL_COMMON = 'http://192.168.10.225:8080/beemabox/common';
// export const BASE_API_URL_TEMPLATE = 'http://192.168.10.225:8081/beemabox/template';
// export const BASE_API_URL_TEMPLATE_NON_LIFE = 'http://192.168.10.225:8082/beemabox/template-nonlife';

// Faisal Ahmed Anik Server API Url
// export const BASE_API_URL = 'http://192.168.10.231:8080/beemabox/common';
// export const BASE_API_URL_COMMON = 'http://192.168.10.231:8080/beemabox/common';
// export const BASE_API_URL_TEMPLATE =
//   'http://192.168.10.231:8081/beemabox/template';
// export const BASE_API_URL_TEMPLATE_NON_LIFE =
//   'http://192.168.10.231:8082/beemabox/template-nonlife';

// Liton Vai Base API Url
// export const BASE_API_URL = 'http://192.168.10.204:8080/beemabox/common';
// export const BASE_API_URL_COMMON = 'http://192.168.10.204:8080/beemabox/common';
// export const BASE_API_URL_TEMPLATE = 'http://192.168.10.204:8081/beemabox/template';
// export const BASE_API_URL_TEMPLATE_NON_LIFE = 'http://192.168.10.204:8082/beemabox/template-nonlife';

// Local Base API Url
// export const BASE_API_URL = 'http://localhost:8080/beemabox/common';
// export const BASE_API_URL_COMMON = 'http://localhost:8080/beemabox/common';
// export const BASE_API_URL_TEMPLATE = 'http://localhost:8081/beemabox/template';
// export const BASE_API_URL_TEMPLATE_NON_LIFE =
//   'http://localhost:8082/beemabox/template-nonlife';
// export const GOOGLE_CLIENT_ID =
//   '1016994148249-cvijokf575kprvsj6899n1ms5a213uu4.apps.googleusercontent.com';
// export const FACEBOOK_CLIENT_ID = '282577308941815';

// Live Base API Url
export const BASE_API_URL = 'https://hoothut.celloscope.net/beemabox/common';
export const BASE_API_URL_COMMON = 'https://hoothut.celloscope.net/beemabox/common';
export const BASE_API_URL_TEMPLATE ='https://hoothut.celloscope.net/beemabox/template';
export const BASE_API_URL_TEMPLATE_NON_LIFE = 'https://hoothut.celloscope.net/beemabox/template-nonlife';
export const GOOGLE_CLIENT_ID = '325187594191-rbtmn4tsp7us27vo06uiv0vv938q0ii7.apps.googleusercontent.com';
export const FACEBOOK_CLIENT_ID = '282577308941815';

export const UPLOAD_IMAGE_URL =
  BASE_API_URL_COMMON + '/' + FILE_DOWNLOAD + '/files/';
