import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  ADMIN_LOGIN_MENUS_LOCAL_STORAGE_KEY,
  emailRegEx,
  LOGIN_LOCAL_STORAGE_KEY,
  mobileNoRegEx,
  ROLE_TYPE_ADMIN,
  ROLE_TYPE_CUSTOMER,
  ROLE_TYPE_INSURER,
  ROLE_TYPE_REGISTERED_USER,
  ROLE_TYPE_UNIT_PROMOTER,
} from '@app/@utlity/constant/constant';
import { SignInRequest } from '@app/@utlity/model/model/sign-in/sign-in-request';
import { SignInRequestBody } from '@app/@utlity/model/model/sign-in/sign-in-request-body';
import { SignUpRequest } from '@app/@utlity/model/model/sign-up/sign-up-request';
import { SignUpRequestBody } from '@app/@utlity/model/model/sign-up/sign-up-request-body';
import { UserInfo } from '@app/@utlity/model/model/user-info';
import { Header } from '@app/@utlity/model/request/base-request';
import { RequestHeader } from '@app/@utlity/model/request/request-header';
import { AppStorageService } from '@app/@utlity/services/app.storage.service';
import { SignInService } from '@app/@utlity/services/sign-in.service';
import { SignUpService } from '@app/@utlity/services/sign-up.service';
import { UserInfoService } from '@app/@utlity/services/user-info.service';
import { WebPortalService } from '@app/@utlity/services/web-portal.service';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '..';

@Component({
  selector: 'app-authentication-application-modal',
  templateUrl: './authentication-application-modal.component.html',
  styleUrls: ['./authentication-application-modal.component.scss'],
})
export class AuthenticationApplicationModalComponent implements OnInit
{
  public header: Header = new Header();
  public userInfo: UserInfo = new UserInfo();
  //public planObj : MasterPlanData = new MasterPlanData();
  public planObj: any;
  public showSignInPage: boolean;
  public showSignUpPage: boolean;
  public signInTitle: String;

  public signInForm: FormGroup;
  public signInData: SignInRequestBody = new SignInRequestBody();
  emailAddressPattern = emailRegEx;
  mobileNoPattern = mobileNoRegEx;
  public signUpForm: FormGroup;
  @ViewChild('fullName') fullName: any;
  @ViewChild('email') email: any;
  @ViewChild('mobileNo') mobileNo: any;
  @ViewChild('password') password: any;
  public emailAvailable: boolean;

  public newSignUp: SignUpRequestBody = new SignUpRequestBody();

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private _appStorageService: AppStorageService,
    private authService: AuthenticationService,
    private _webPortalService: WebPortalService,
    private _signInService: SignInService,
    private _signUpService: SignUpService,
    private socialAuthService: SocialAuthService,
    private userInfoService: UserInfoService,
    private _spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AuthenticationApplicationModalComponent>
  ) {
  }
  
	private socialUser: SocialUser;
  public authorized: boolean = false;
  public requestHeader: RequestHeader = new RequestHeader();
  public userInfoRequest: SignInRequest = new SignInRequest();
  public userInfoRequestBody: SignInRequestBody = new SignInRequestBody();

  signInWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(
      (userData) => {
        // Now sign-in with userData        
        if (userData != null) {
            this.authorized = true;
            this.socialUser = userData;   
            this.checkUserInfoBySocialUser(this.socialUser.email);                    
        }       
      }
    );
  }

  signInWithFB(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
      (userData) => {
        // Now sign-in with userData        
        if (userData != null) {
            this.authorized = true;
            this.socialUser = userData;
            this.checkUserInfoBySocialUser(this.socialUser.email);           
        }       
      }
    );
  }

  checkUserInfoBySocialUser(loginId: string){

    this.requestHeader.requestId = this.header.requestId;
    this.requestHeader.requestDateTime = this.header.requestDateTime;
    this.requestHeader.requestSource = this.header.requestSource;
    this.requestHeader.requestServiceSource =  this.header.requestServiceSource;

    this.userInfoRequest.header = this.requestHeader;
    this.userInfoRequest.body = this.userInfoRequestBody;
    this.userInfoRequest.body.loginId = loginId;
    this._spinner.show();
    
    this.userInfoService.getCustomerUserInfoByLoginId(this.userInfoRequest).subscribe(
      (data) => {
        this._spinner.hide();
        debugger;
        if (data.header.responseCode == '200') {
          localStorage.setItem('loggedIn', 'true');
          this._appStorageService.setData(LOGIN_LOCAL_STORAGE_KEY, JSON.stringify(data.body));
          this._appStorageService.setData(ADMIN_LOGIN_MENUS_LOCAL_STORAGE_KEY, data.body.accessJson);
          if (this.planObj.planLocalStorageKey != null) {
            this._appStorageService.setData(this.planObj.planLocalStorageKey, JSON.stringify(this.planObj));
          }
          if (data.body.roleType == ROLE_TYPE_INSURER) {
            this.router.navigate(['insurer/dashboard']);
          } else if (data.body.roleType == ROLE_TYPE_ADMIN) {
            this.router.navigate(['admin/dashboard']);
          } else if (data.body.roleType == ROLE_TYPE_CUSTOMER || data.body.roleType == ROLE_TYPE_REGISTERED_USER) {
            if (this.planObj.insurerPrefix == 'temn') {
              var routerPath = this.planObj.insurerPrefix + '/customer/' + this.planObj.insurerTypeUrl + '/' + this.planObj.nonLifePlanType + '/' + this.planObj.planType.toLowerCase() + '/application/form';
              this.router.navigate([routerPath]);
            } else {
              var routerPath = this.planObj.insurerPrefix + '/customer/' + this.planObj.insurerTypeUrl + '/' + this.planObj.planType.toLowerCase().replace(/\s/g, '-') + '/application/form';
              this.router.navigate([routerPath]);
            }
          } else if(data.body.roleType == ROLE_TYPE_UNIT_PROMOTER) {
            var routerPath = this.planObj.insurerPrefix + '/unit-promoter/' + this.planObj.insurerTypeUrl + '/' + this.planObj.planType.toLowerCase().replace(/\s/g, '-') + '/application/form';
            this.router.navigate([routerPath]);
          }

          this.toastr.success('Successfully login', '', { positionClass: 'toast-top-right', closeButton: true });
          this.dialogRef.close('Close');
        } 
        
        if (data != null && data.header.responseCode === 'NoDataFound') {

          this.signUpRequest.header = this.requestHeader;
          this.signUpRequest.body = this.signUpRequestBody;
          this.signUpRequest.body.email = this.socialUser.email;
          this.signUpRequest.body.fullName = this.socialUser.name;
          this.signUpRequest.body.nameEn = this.socialUser.name;
          this.signUpRequest.body.loginId = this.socialUser.email;
          this.signUpRequest.body.photoUrl = this.socialUser.photoUrl;
          
          this._signUpService.saveSignUpInformation(this.signUpRequest).subscribe(
            (data) => {
              this._spinner.hide();                
              if (data != null && data.header.responseCode === '200') {
                if (data.body.loginStatus == 'OK') {
                  this.checkUserInfoBySocialUser(this.socialUser.email);
                } else {
                  this.toastr.warning('Unable To Sign Up', '', {
                      positionClass: 'toast-top-right', closeButton: true,
                  });
                }
              } else {
                this.toastr.warning('Unable To Sign Up', '', {
                    positionClass: 'toast-top-right', closeButton: true,
                });
              }
            },
            (error) => {
              console.log(error);
              this._spinner.hide();
              this.toastr.error(error.message, "", {
                positionClass: "toast-top-right", closeButton: true,
              });
            }
          );
        }
      },
      (error) => {
        this._spinner.hide();
        // this.isError = true;
        // console.log(error);
        // setTimeout(()=>{  this.isError = false; }, 5000);
      }
    );
  }

  get f() {
    return this.signUpForm.controls;
  }

  isValidSignUpForm() {
    this.newSignUp = this.signUpForm.value;
    // if (!this.newSignUp.fullName) {
    //   this.toastr.warning('Please enter your full name.', '', {
    //     positionClass: 'toast-top-right',
    //     closeButton: true,
    //   });
    //   this.fullName.nativeElement.focus();
    //   return false;
    // }
    if (!this.newSignUp.mobileNo) {
      this.toastr.warning('Please enter your valid mobile no.', '', {
        positionClass: 'toast-top-right', closeButton: true,
      });
      this.mobileNo.nativeElement.focus();
      return false;
    }
    if (this.newSignUp.mobileNo) {
      if (!this.newSignUp.mobileNo.match(this.mobileNoPattern)) {
        this.toastr.warning('Please enter valid mobile number.', '', {
          positionClass: 'toast-top-right', closeButton: true,
        });
        this.mobileNo.nativeElement.focus();
        return false;
      }
    }
    if (this.newSignUp.email) {
      if (!this.newSignUp.email.match(this.emailAddressPattern)) {
        this.toastr.warning('Please enter a valid email address.', '', {
          positionClass: 'toast-top-right', closeButton: true,
        });
        this.email.nativeElement.focus();
        return false;
      }
    }
    if (!this.newSignUp.password) {
      this.toastr.warning('Please create your password.', '', {
        positionClass: 'toast-top-right', closeButton: true,
      });
      this.password.nativeElement.focus();
      return false;
    }
    return true;
  }

  goToCustomerSignUpPage() {
    this.showSignInPage = false;
    this.showSignUpPage = true;
    this.signInTitle = 'Sign Up';
  }

  emailAvailablity(e: any) {
    //this.email = { email: e.target.value };
    //this.emailAvailable = true;
    /* this._authService.checkEmail(this.email).subscribe((res: Server_Response) => {
          this.emailAvailable = !res.data;
      })*/
  }

  cloasModal() {
    this.dialogRef.close('Close');
  }

  apply() {
    this.dialogRef.close('Submit');
  }

  goToCustomerLoginPage() {
    this.showSignInPage = true;
    this.showSignUpPage = false;
    this.signInTitle = 'Sign In';
  }

  ngOnInit(): void {
    this.planObj = this.data.obj;
    this.signInTitle = 'Sign In';
    this.showSignInPage = true;
    this.showSignUpPage = false;

    this.signInForm = this.formBuilder.group({
      loginId: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.signUpForm = this.formBuilder.group({
      fullName: ['', Validators.required],
      email: [''],
      mobileNo: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  signInRequest: SignInRequest = new SignInRequest();
  signInRequestHeader: RequestHeader = new RequestHeader();
  signInRequestBody: SignInRequestBody = new SignInRequestBody();

  doLogin() {
    this.signInRequestHeader.requestId = this.header.requestId;
    this.signInRequestHeader.requestDateTime = this.header.requestDateTime;
    this.signInRequestHeader.requestSource = this.header.requestSource;
    this.signInRequestHeader.requestServiceSource =
      this.header.requestServiceSource;

    this.signInData = this.signInForm.value;

    this.signInRequest.body = this.signInData;
    this.signInRequest.header = this.signInRequestHeader;

    this._signInService.doLogin(this.signInRequest).subscribe(
      (data) => {
        this.authService.customerLogInResponseBody = data.body;
        if (data.body.loginStatus == 'OK') {
          if (this.planObj.planLocalStorageKey != null) {
            //this._appStorageService.setData(SELECTED_ENDOWMENT_PLAN_LOCAL_STORAGE_KEY, JSON.stringify(this.planObj));
            this._appStorageService.setData(
              this.planObj.planLocalStorageKey,
              JSON.stringify(this.planObj)
            );
          }
          this._appStorageService.setData(
            ADMIN_LOGIN_MENUS_LOCAL_STORAGE_KEY,
            data.body.accessJson
          );

          this._appStorageService.setData('loggedIn', 'true');
          this._appStorageService.setData(
            LOGIN_LOCAL_STORAGE_KEY,
            JSON.stringify(data.body)
          );

          if (data.body.roleType == 'Customer') {
            if (this.planObj.insurerPrefix == 'temn') {
              var routerPath =
                this.planObj.insurerPrefix +
                '/' +
                data.body.roleType.toLowerCase() +
                '/' +
                this.planObj.insurerTypeUrl +
                '/' +
                this.planObj.nonLifePlanType +
                '/' +
                this.planObj.planType.toLowerCase() +
                '/application/form';
              this.router.navigate([routerPath]);
            } else {
              debugger;
              var routerPath =
                this.planObj.insurerPrefix +
                '/customer/' +
                this.planObj.insurerTypeUrl +
                '/' +
                this.planObj.planType.toLowerCase().replace(/\s/g, '-') +
                '/application/form';
              this.router.navigate([routerPath]);
            }
          } else if (data.body.roleType == 'Insurer') {
            this.router.navigate(['insurer/dashboard']);
          } else if (data.body.roleType == 'Admin') {
            this.router.navigate(['admin/dashboard']);
          } else if (data.body.roleType == ROLE_TYPE_REGISTERED_USER) {
            if (this.planObj.insurerPrefix == 'temn') {
              var routerPath =
                this.planObj.insurerPrefix +
                '/customer/' +
                this.planObj.insurerTypeUrl +
                '/' +
                this.planObj.nonLifePlanType +
                '/' +
                this.planObj.planType.toLowerCase() +
                '/application/form';
              this.router.navigate([routerPath]);
            } else {
              debugger;
              var routerPath =
                this.planObj.insurerPrefix +
                '/customer/' +
                this.planObj.insurerTypeUrl +
                '/' +
                this.planObj.planType.toLowerCase().replace(/\s/g, '-') +
                '/application/form';
              this.router.navigate([routerPath]);
            }
          } else if(data.body.roleType == ROLE_TYPE_UNIT_PROMOTER) {
            var routerPath = this.planObj.insurerPrefix + '/unit-promoter/' + this.planObj.insurerTypeUrl + '/' + this.planObj.planType.toLowerCase().replace(/\s/g, '-') + '/application/form';
            this.router.navigate([routerPath]);
          }
          
          
          this.toastr.success('Successfully login', '', {
            positionClass: 'toast-top-right',
            closeButton: true,
          });
          this.dialogRef.close('Close');
        } else {
          this.toastr.success('Unable login', '', {
            positionClass: 'toast-top-right',
            closeButton: true,
          });
        }
      },
      (error) => console.log(error)
    );
  }

  signUpRequest: SignUpRequest = new SignUpRequest();
  signUpRequestHeader: RequestHeader = new RequestHeader();
  signUpRequestBody: SignUpRequestBody = new SignUpRequestBody();

  signUpInformation() {
    if (!this.isValidSignUpForm()) {
      return;
    }
    this.signUpRequestHeader.requestId = this.header.requestId;
    this.signUpRequestHeader.requestDateTime = this.header.requestDateTime;
    this.signUpRequestHeader.requestSource = this.header.requestSource;
    this.signUpRequestHeader.requestServiceSource =
      this.header.requestServiceSource;

    this.newSignUp = this.signUpForm.value;
    this.newSignUp.loginId = this.newSignUp.mobileNo;
    // this.newSignUp.userName = this.newSignUp.email;

    this.signUpRequest.body = this.newSignUp;
    this.signUpRequest.header = this.signUpRequestHeader;

    this._signUpService.saveSignUpInformation(this.signUpRequest).subscribe(
      (data) => {
        if (data.body.loginStatus == 'OK') {
          this._webPortalService.customerUserInfo.next(data.body);
          this.signInForm.controls.loginId.setValue(this.newSignUp.loginId);
          this.signInForm.controls.password.setValue(this.newSignUp.password);
          this.doLogin();
        } else {
          this.toastr.warning(
            'Unable To Save Insurance Seeker Registration',
            '',
            {
              positionClass: 'toast-top-right',
              closeButton: true,
            }
          );
        }
      },
      (error) => console.log(error)
    );
  }
}
