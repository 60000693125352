import { ResponseHeader } from "../../response/response-header";

export class SignUpRequestBody {
    fullName: String;
    nameEn: String;
    nameBn: String;
    email: String;
    password: String;
    mobileNo: String;
    userName: String;
    loginId: String;
    photoUrl: String;
}
