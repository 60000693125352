import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { BASE_API_URL, COMMON_RESEND_OTP_API_V1, COMMON_RESEND_OTP_BY_LOGIN_ID_API_V1, COMMON_RESEND_OTP_BY_MOBILE_API_V1, COMMON_SEND_OTP_API_V1, COMMON_SEND_OTP_BY_LOGIN_ID_API_V1, COMMON_SEND_OTP_BY_MOBILE_API_V1, COMMON_VERIFY_OTP_API_V1, COMMON_VERIFY_OTP_BY_LOGIN_ID_API_V1, COMMON_VERIFY_OTP_BY_MOBILE_API_V1 } from '../constant/constant';
import { Header } from '../model/request/base-request';
import { RequestHeader } from '../model/request/request-header';
import { ResendOtpRequest } from '../model/request/resend-otp-request';
import { SendOtpRequest } from '../model/request/send-otp-request';
import { SendOtpRequestBody } from '../model/request/send-otp-request-body';
import { VerifyOtpRequest } from '../model/request/verify-otp-request';
import { VerifyOtpRequestBody } from '../model/request/verify-otp-request-body';
import { ResendOtpResponse } from '../model/response/resend-otp-response';
import { SendOtpResponse } from '../model/response/send-otp-response';
import { SendOtpResponseBody } from '../model/response/send-otp-response-body';
import { VerifyOtpResponse } from '../model/response/verify-otp-response';
import { VerifyOtpResponseBody } from '../model/response/verify-otp-response-body';

@Injectable({
  providedIn: 'root'
})
export class OtpService {
  
  public header: Header = new Header();
  public requestHeader: RequestHeader = new RequestHeader();

  public sendOtpRequest: SendOtpRequest = new SendOtpRequest();
  public sendOtpRequestBody: SendOtpRequestBody = new SendOtpRequestBody();
  public sendOtpResponse: SendOtpResponse = new SendOtpResponse();
  public sendOtpResponseBody: SendOtpResponseBody = new SendOtpResponseBody();
  public sendOtpResponseData: SendOtpResponseBody = new SendOtpResponseBody();

  public verifyOtpRequest: VerifyOtpRequest = new VerifyOtpRequest();
  public verifyOtpRequestBody: VerifyOtpRequestBody = new VerifyOtpRequestBody();
  public verifyOtpResponse: VerifyOtpResponse = new VerifyOtpResponse();
  public verifyOtpResponseBody: VerifyOtpResponseBody = new VerifyOtpResponseBody();

  constructor(
    private httpClient: HttpClient,
    private spinner: NgxSpinnerService
  ) {}

  sendOtp(sendOtpRequest: SendOtpRequest): Observable<SendOtpResponse> {
    return this.httpClient.post<SendOtpResponse>(
      `${BASE_API_URL}/${COMMON_SEND_OTP_API_V1}`,
      sendOtpRequest
    );
  }

  resendOtp(resendOtpRequest: ResendOtpRequest): Observable<ResendOtpResponse> {
    return this.httpClient.post<ResendOtpResponse>(
      `${BASE_API_URL}/${COMMON_RESEND_OTP_API_V1}`,
      resendOtpRequest
    );
  }

  verifyOtp(verifyOtpRequest: VerifyOtpRequest): Observable<VerifyOtpResponse> {
    return this.httpClient.post<VerifyOtpResponse>(
      `${BASE_API_URL}/${COMMON_VERIFY_OTP_API_V1}`,
      verifyOtpRequest
    );
  }
  
  sendOtpByLoginId(sendOtpRequest: SendOtpRequest): Observable<SendOtpResponse> {
    return this.httpClient.post<SendOtpResponse>(
      `${BASE_API_URL}/${COMMON_SEND_OTP_BY_LOGIN_ID_API_V1}`,
      sendOtpRequest
    );
  }

  resendOtpByLoginId(resendOtpRequest: ResendOtpRequest): Observable<ResendOtpResponse> {
    return this.httpClient.post<ResendOtpResponse>(
      `${BASE_API_URL}/${COMMON_RESEND_OTP_BY_LOGIN_ID_API_V1}`,
      resendOtpRequest
    );
  }

  verifyOtpByLoginId(verifyOtpRequest: VerifyOtpRequest): Observable<VerifyOtpResponse> {
    return this.httpClient.post<VerifyOtpResponse>(
      `${BASE_API_URL}/${COMMON_VERIFY_OTP_BY_LOGIN_ID_API_V1}`,
      verifyOtpRequest
    );
  }
  
  sendOtpByMobileNo(sendOtpRequest: SendOtpRequest): Observable<SendOtpResponse> {
    return this.httpClient.post<SendOtpResponse>(
      `${BASE_API_URL}/${COMMON_SEND_OTP_BY_MOBILE_API_V1}`,
      sendOtpRequest
    );
  }

  resendOtpByMobileNo(resendOtpRequest: ResendOtpRequest): Observable<ResendOtpResponse> {
    return this.httpClient.post<ResendOtpResponse>(
      `${BASE_API_URL}/${COMMON_RESEND_OTP_BY_MOBILE_API_V1}`,
      resendOtpRequest
    );
  }

  verifyOtpByMobileNo(verifyOtpRequest: VerifyOtpRequest): Observable<VerifyOtpResponse> {
    return this.httpClient.post<VerifyOtpResponse>(
      `${BASE_API_URL}/${COMMON_VERIFY_OTP_BY_MOBILE_API_V1}`,
      verifyOtpRequest
    );
  }

  getSendOtpByLoginId(loginId: any){
    this.requestHeader.requestId = this.header.requestId;
    this.requestHeader.requestDateTime = this.header.requestDateTime;
    this.requestHeader.requestSource = this.header.requestSource;
    this.requestHeader.requestServiceSource = this.header.requestServiceSource;

    this.sendOtpRequest.header = this.requestHeader;
    this.sendOtpRequest.body = this.sendOtpRequestBody;
    this.sendOtpRequest.body.loginId = loginId;
    debugger;
    //this.spinner.show();
    var keepResult = false;
    this.sendOtp(this.sendOtpRequest).subscribe(
      (data) => {
        debugger;
        console.log(data);
        console.log(data.body.otp);
        if (data.header.responseCode == '200') {
          if(data.body.smsStatus == "Sent"){
            console.log(data.body);
            this.sendOtpResponseBody = data.body;
            this.sendOtpResponseData = data.body;
            keepResult = true;

            this.setSendOtpResponseBody(this.sendOtpResponseBody);
          } else {
            keepResult = false;
          }
          console.log(this.sendOtpResponseBody);
        } else {
          this.sendOtpResponseBody = null;
          keepResult = false;
        }
      },
      (error) => {
        console.log(error);
        //this.spinner.hide();
        this.sendOtpResponseBody = null;
        keepResult = false;
      }
    );
    //this.spinner.hide();
    //return this.sendOtpResponseBody;
    return keepResult;
  }

  setSendOtpResponseBody(obj:SendOtpResponseBody){
    this.sendOtpResponseData = obj;
    return this.sendOtpResponseData;
  }

}
