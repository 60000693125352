import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASE_API_URL, COMMON_RESET_PASSWORD_API_V1 } from '../constant/constant';
import { ResetPasswordRequest } from '../model/request/reset-password-request';
import { ResetPasswordResponse } from '../model/response/reset-password-response';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  constructor(private httpClient: HttpClient) {}

  resetPassword(resetPasswordRequest: ResetPasswordRequest): Observable<ResetPasswordResponse> {
    return this.httpClient.post<ResetPasswordResponse>(
      `${BASE_API_URL}/${COMMON_RESET_PASSWORD_API_V1}`,
      resetPasswordRequest
    );
  }
}
