import { NgModule } from '@angular/core';
import {
  HttpBackend,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '@env/environment';
import { CoreModule } from '@core';
import { AuthModule } from '@app/auth';
import { ShellModule } from './shell/shell.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from './@shared';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CommonLayoutModule } from './@common/module/common-layout.module';
import { NgOtpInputModule } from 'ng-otp-input';
import { AppReportComponent } from './hoothut-application/common/app-report/app-report.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PdfViewerComponent } from './hoothut-application/common/app-report/shared/dialog/pdfViewer.component';
import { PdfViewerByUrlComponent } from './hoothut-application/common/app-report/shared/dialog/pdf-viewer-by-url.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ApplicationConfirmationModalComponent } from './hoothut-application/common/shared/modal/application-confirmation-modal/application-confirmation-modal.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

export function createTranslateLoader(httpBackend: HttpBackend) {
  return new TranslateHttpLoader(
    new HttpClient(httpBackend),
    '../assets/i18n/',
    '.json'
  );
  //return new TranslateHttpLoader(new HttpClient(httpBackend), '../translations/', '.json');
}

@NgModule({
  imports: [
    CommonLayoutModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgOtpInputModule,
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environment.production,
    }),
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpBackend],
      },
    }),
    NgbModule,
    CoreModule,
    ShellModule,
    AuthModule,
    BsDatepickerModule.forRoot(),
    ToastrModule.forRoot(),
    SharedModule,
    NgxExtendedPdfViewerModule,
    NgxSpinnerModule,
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
  ],
  declarations: [
    AppComponent,
    PdfViewerComponent,
    PdfViewerByUrlComponent,
    AppReportComponent,
    ApplicationConfirmationModalComponent
  ],
  exports: [PdfViewerComponent, PdfViewerByUrlComponent],
  entryComponents: [PdfViewerComponent, PdfViewerByUrlComponent],

  //providers: [],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
