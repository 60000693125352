// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//import { EnvironmentModel } from "./environment-model";

// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.

// environment : { production: boolean; hmr: boolean; version: string; serverUrl: string; apiPrefix: string; defaultLanguage: string; supportedLanguages: string[]; };
/*
const environmentobj = new EnvironmentModel();
const environmen = new EnvironmentModel();
environmentobj.production = false;
environmentobj.baseURL = 'http://localhost:8080/hoothut/server/api';
environmentobj.hmr = true;
environmentobj.version = '1.0.0';
environmentobj.serverUrl = 'http://localhost:4200';
environmentobj.apiPrefix = ':9020';
environmentobj.defaultLanguage = 'English';
environmentobj.supportedLanguages = ['English', 'বাংলা'];

export const environment = environmentobj;
*/

export const environment = {
  production: false,
  //baseURL: 'http://localhost:8080/hoothut/server/api',
  baseURL: 'http://localhost:8080/hoothut/server/api',
  hmr: true,
  version: '1.0.0',
  serverUrl: 'http://localhost:4200',
  apiPrefix: ':9020',
  defaultLanguage: 'English',
  supportedLanguages: ['English', 'বাংলা'],
}; 


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
