    <div class="card">
      <div class="card-header">
        <button type="submit" class="close btn ml-1" (click)="cloasModal('No')">
          <i class="far fa-window-close"></i>
        </button>
        <h3 class="text-left">{{ title | translate }}</h3>
      </div>
  
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 text-center">
            <label id="msg"></label>
          </div>
        </div>
        <div class="row">
            <p>
                {{ message | translate }}
            </p>
        </div>
        
      </div>
  
      <div class="card-footer">
        <div class="form-group text-center">
          <button type="submit" class="btn btn-success" (click)="cloasModal('Yes')">
            {{ 'Button_Yes' | translate }}
          </button>
          <button
            type="button"
            class="btn btn-outline-danger ml-1"
            (click)="cloasModal('No')"
          >
            <i class="fa fa-close"></i> {{ 'Button_No' | translate }}
          </button>
        </div>
      </div>
    </div>
  