
import { v4 as uuid } from 'uuid';
import * as moment from 'moment-timezone';
import { REQUEST_SERVICE_SOURCE, REQUEST_SOURCE } from '@app/@utlity/constant/constant';

export class RequestHeader {
    requestId: String = uuid();
    requestDateTime: String = moment().tz('Asia/Dhaka').format('YYYY-MM-DDTHH:mm:ss.SSSSSS');
    requestSource: String = REQUEST_SOURCE;
    requestServiceSource: String = REQUEST_SERVICE_SOURCE;
}

