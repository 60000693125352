export class UserInfo {
  nameEn: string;
  nameBn: string;
  email: string;
  mobileNo: string;
  loginId: string;
  registrationId: string;
  insurerId: string;
  insurerType: string;
  userName: string;
  userPhotoPath: string;
  userPhotoUrl: string;

  accessJson: string;
  roleDescription: string;
  roleType: string;
  isVerified: String;
  isExistPassword: boolean;

  loginStatus: string;
  authorizationKey: string;
  loginDateTime: Date;

  unitPromoterId: string;
  clusterOid: string;
  clusterCode: string;
  clusterNameEn: string;
  clusterNameBn: string;
}
