import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CustomerLogInRequest } from './model/customer-log-in-request';
import { CustomerLogInResponse } from './model/customer-log-in-response';
import { BASE_API_URL, SECURITY_LOGIN_API_V1 } from '@app/@utlity/constant/constant';

@Injectable({
  providedIn: 'root',
})
export class CustomerLoginService {
  constructor(private httpClient: HttpClient) {}

  //public baseUrl = BASE_API_URL;
  //public baseUrl = 'http://localhost:8080/hoothut/server/api';
  public baseUrl = 'http://localhost:8080/beemabox/common';
/*
  doLogin(
    customerLogInRequest: CustomerLogInRequest
  ): Observable<CustomerLogInResponse> {
    return this.httpClient.post<CustomerLogInResponse>(
      `${BASE_API_URL}/${SECURITY_LOGIN_API_V1}`,
      customerLogInRequest
    );
  }*/

  doLogin(
    customerLogInRequest: CustomerLogInRequest
  ): Observable<CustomerLogInResponse> {
    return this.httpClient.post<CustomerLogInResponse>(
      `${BASE_API_URL}/${SECURITY_LOGIN_API_V1}`,
      customerLogInRequest
    );
  } 
}
