import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateLocaleFilter } from './date-locale-filter';
import { LocalCurrencyPipe } from './locale-currency.pipe';
import { LocalNumberPipe } from './locale-number.pipe';
import { TranslatorPipe } from './translator-pipe';

@NgModule({
  imports: [CommonModule],
  providers: [
    TranslatorPipe,
    LocalNumberPipe,
    LocalCurrencyPipe,
    DateLocaleFilter,
  ],
  declarations: [
    TranslatorPipe,
    LocalNumberPipe,
    LocalCurrencyPipe,
    DateLocaleFilter,
  ],
  exports: [
    TranslatorPipe,
    LocalNumberPipe,
    LocalCurrencyPipe,
    DateLocaleFilter,
  ],
})
export class SharedPipesModule {}
