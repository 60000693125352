import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppStorageService } from '@app/@utlity/services/app.storage.service';
import { TitleService } from '@app/@utlity/services/title.service';
import { AdminSidebarService } from '@app/admin/layout/admin-sidebar/admin-sidebar.service';
import { TranslateService } from '@ngx-translate/core';
import { WebPortalService } from '@app/@utlity/services/web-portal.service';
import {
  CHANGE_ROLE_REGISTERED_USER_TO_CUSTOMER,
  LOGIN_LOCAL_STORAGE_KEY,
  UPDATE_USER_INFO,
} from '@app/@utlity/constant/constant';
import { UserInfo } from '@app/@utlity/model/model/user-info';
import { UserInfoService } from '@app/@utlity/services/user-info.service';

declare var $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, AfterViewInit {
  title: string;
  public menus: {};
  public locale: string;
  public userInfo: UserInfo = new UserInfo();
  public showLanguage: string;
  public LANGUAGE_BANGLA = 'বাংলা';
  public LANGUAGE_ENGLISH = 'English';
  public buttonIconState: boolean;

  constructor(
    public sidebarservice: AdminSidebarService,
    private titleService: TitleService,
    private router: Router,
    private _userInfoService: UserInfoService,
    private _appStorageService: AppStorageService,
    private _webPortalService: WebPortalService,
    private translate: TranslateService
  ) {
    this._userInfoService.updateUserInfoMessage.subscribe((message) => {
      if (message == UPDATE_USER_INFO) {
        this.userInfo = JSON.parse(
          this._appStorageService.getData(LOGIN_LOCAL_STORAGE_KEY)
        );
      }
    });

    if (this.translate.currentLang === 'bn') {
      this.locale = 'bn';
      this.showLanguage = this.LANGUAGE_ENGLISH;
    } else {
      this.showLanguage = this.LANGUAGE_BANGLA;
      this.locale = 'en';
    }
  }
  changeLangToEnglish() {
    this.translate.use('en');
    this.locale = 'en';
  }
  changeLangToBangla() {
    this.translate.use('bn');
    this.locale = 'bn';
  }
  // changeLang() {
  //   if (this.translate.currentLang === 'bn') {
  //     this.translate.use('en');
  //     this.locale = 'en';
  //     this.showLanguage = this.LANGUAGE_BANGLA;
  //   } else {
  //     this.translate.use('bn');
  //     this.locale = 'bn';
  //     this.showLanguage = this.LANGUAGE_ENGLISH;
  //   }
  //   this.setActiveLanguageLink();
  // }

  changeLanguage(lan: string) {
    this.translate.use(lan);
    this.locale = lan;
    this.setActiveLanguageLink();
  }

  goToProfileEditPage() {
    debugger;
    if (this.userInfo.roleType == 'Customer') {
      this.router.navigate(['/customer/profile/my-profile']);
    }
  }

  setActiveLanguageLink() {
    if (this.translate.currentLang === 'en') {
      $('#englishLangLink').addClass('active');
      $('#banglaLangLink').removeClass('active');
      document.documentElement.setAttribute('lang', 'en');
    } else if (this.translate.currentLang === 'bn') {
      $('#banglaLangLink').addClass('active');
      $('#englishLangLink').removeClass('active');
      document.documentElement.setAttribute('lang', 'bn');
    }
  }

  ngOnInit(): void {
    // this.locale = this.translate.currentLang;
    // this.translate.onLangChange.subscribe((s: { lang: string }) => {
    //   if (s.lang === 'en') {
    //     this.locale = 'en';
    //     this.showLanguage = this.LANGUAGE_BANGLA;
    //   } else {
    //     this.locale = 'bn';
    //   this.showLanguage = this.LANGUAGE_ENGLISH;
    //   }
    // });

    this.buttonIconState = this.sidebarservice.getSidebarState();

    this.titleService
      .getTitle()
      .subscribe((appTitle) => (this.title = appTitle));
    this.userInfo = JSON.parse(
      this._appStorageService.getData(LOGIN_LOCAL_STORAGE_KEY)
    );
    if (!this.userInfo.nameBn) {
      this.userInfo.nameBn = this.userInfo.nameEn;
    }
  }

  toggleSidebar() {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
    this.buttonIconState = this.sidebarservice.getSidebarState();
  }

  toggleBackgroundImage() {
    this.sidebarservice.hasBackgroundImage =
      !this.sidebarservice.hasBackgroundImage;
  }

  ngAfterViewInit() {
    setTimeout(() => this.setActiveLanguageLink());
  }

  hideSidebar() {
    this.sidebarservice.setSidebarState(true);
  }
  logout(): void {
    //this._authService.logout();
    localStorage.clear();
    this._webPortalService.loginState.next(false);
    localStorage.setItem('loggedIn', 'false');
    this.router.navigate(['/']);
  }
}
