export class SendOtpRequestBody {
    loginId: string;
    otpRequestBy: string;
    mobileNo: string;
    insurerId: string;
    applicationTrackingId: string;
    offerId: string;
    features: string;
    notes: string;
    createdBy: string;
}
