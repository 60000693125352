import { CLIENT_NAME, REQUEST_VERSION, REQUEST_TIMEOUT_IN_SECONDS, REQUEST_SOURCE, REQUEST_SERVICE_SOURCE } from '../../constant/constant';
import * as moment from 'moment-timezone';
import { v4 as uuid } from 'uuid';

export class Header {
    requestId: string = uuid();
    requestClient: string = CLIENT_NAME;
    requestType: string;
    requestSource: string = REQUEST_SOURCE;
    requestSourceService: string = REQUEST_SERVICE_SOURCE;
    requestServiceSource: string = REQUEST_SERVICE_SOURCE;
    requestVersion: string = REQUEST_VERSION;
    requestTime: string = moment().tz('Asia/Dhaka').format('YYYY-MM-DDTHH:mm:ss.SSSSSS') + 'Z';
    requestDateTime: string = moment().tz('Asia/Dhaka').format('YYYY-MM-DDTHH:mm:ss.SSSSSS');
    requestTimeoutInSeconds: number = REQUEST_TIMEOUT_IN_SECONDS;
    requestRetryCount = 0;
}

export abstract class BaseRequest {
    header: Header = new Header();
    meta: object = new Object();
}
