import { Credentials } from './../../../auth/credentials.service';
import { BaseRequest } from './base-request';
class Body {
    applicationName: string;
    authenticationToken: string;
    clientId: string;
    userId: string;
    password: string;
    location: string;
}


export class RequestLogin extends BaseRequest {
    body: Body = new Body();
    constructor(url: string, requestSourceService: string, applicationName: string,
        authenticationToken: string, clientId: string, username: string, password: string, locationInfo: any) {
       super();
       this.header.requestType = url;
       this.header.requestSourceService = requestSourceService;
       this.body.applicationName = applicationName;
       this.body.authenticationToken = authenticationToken;
       this.body.clientId = clientId;
       this.body.userId = username;
       this.body.password = password;
       this.body.location = locationInfo;
   }
}
