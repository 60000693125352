import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConstantService } from '@app/@utlity/services/constant.service';

@Component({
    selector: 'app-application-confirmation-modal',
    templateUrl: './application-confirmation-modal.component.html',
    styleUrls: ['./application-confirmation-modal.component.scss']
})
export class ApplicationConfirmationModalComponent implements OnInit {
    
    public title: string = "Confirmation_Title";
    public message: string = "Application_Confirmation_Message";

    constructor(
        private constantService: ConstantService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ApplicationConfirmationModalComponent>
    ) { }

    cloasModal(status: string) {
        this.dialogRef.close(status);
    }

    ngOnInit(): void {
        console.log(this.dialogRef);
         this.dialogRef.updateSize('40%', 'auto');
        // this.dialogRef.updatePosition({ top: '50px' });
        if(!this.constantService.isNullOrEmpty(this.data)){
            if(!this.constantService.isNullOrEmpty(this.data.title)){
                this.title = this.data.title;
            }
            if(!this.constantService.isNullOrEmpty(this.data.message)){
                this.message = this.data.message;
            }
        }
    }

}
