import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminSidebarComponent } from '@app/admin/layout/admin-sidebar/admin-sidebar.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { WebFooterComponent } from '@app/web/layout/web-footer/web-footer.component';
import { NavbarComponent } from '../navbar/navbar.component';
import { I18nModule } from '@app/i18n';

@NgModule({
  declarations: [AdminSidebarComponent, NavbarComponent, WebFooterComponent],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PerfectScrollbarModule,
    ToastrModule.forRoot(),
    I18nModule,
  ],
  exports: [
    AdminSidebarComponent,
    NavbarComponent,
    WebFooterComponent,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PerfectScrollbarModule,
    ToastrModule,
    I18nModule,
  ],
})
export class CommonLayoutModule {}
