import { environment } from 'src/environments/environment';

export class API {
    static CECURITY_URL = environment.serverUrl + '/app/cecurity/';
    static BASE_API_URL = environment.serverUrl + environment.apiPrefix + '/insurance/';
    // static BASE_API_URL = 'http://localhost:9020' + '/insurance/';

    static APP_AUTH_URL = 'authentication/application/v1/authenticate-application';
    static CLIENT_AUTH_URL = 'authentication/client/v1/authenticate-client';
    static USER_AUTH_URL = 'authentication/user/v1/authenticate-user';
    static USER_LOGOUT_URL = 'authentication/user/v1/logout';
    static USER_INFO_URL = 'common/enquiry/v1/get-user-info-by-loginId';
    static RESET_PASS_URL = 'authentication/user/v1/password-reset';
    static PASSWORD_POLICY_URL = 'common/enquiry/v1/get-password-policy';
    static VALIDATE_TOKEN_URL = 'authentication/token/v1/validate-token';

}