import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ADMIN_LOGIN_MENUS_LOCAL_STORAGE_KEY,
  CHANGE_ROLE_REGISTERED_USER_TO_CUSTOMER,
  LOGIN_LOCAL_STORAGE_KEY,
  UPDATE_USER_INFO,
} from '@app/@utlity/constant/constant';
import { Login } from '@app/@utlity/model/model/login';
import { AppStorageService } from '@app/@utlity/services/app.storage.service';
import { UserInfoService } from '@app/@utlity/services/user-info.service';
import { WebPortalService } from '@app/@utlity/services/web-portal.service';
import { TranslateService } from '@ngx-translate/core';
import { AdminSidebarService } from './admin-sidebar.service';
declare var $: any;

@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.scss'],
})
export class AdminSidebarComponent implements OnInit {
  public menus: Object[];
  public userName$: any;
  public roleOid$: any;
  public userInfo: Login = new Login();
  public sidebarState: boolean;

  constructor(
    public sidebarservice: AdminSidebarService,
    private router: Router,
    private _webPortalService: WebPortalService,
    private translate: TranslateService,
    private _userInfoService: UserInfoService,
    private _appStorageService: AppStorageService
  ) {
    let accessJson = this._appStorageService.getData(
      ADMIN_LOGIN_MENUS_LOCAL_STORAGE_KEY
    );

    if (accessJson != null) {
      this.menus = JSON.parse(accessJson);
    }
    this.userInfo = JSON.parse(
      this._appStorageService.getData(LOGIN_LOCAL_STORAGE_KEY)
    );
    //translate.setDefaultLang('bn');
    //translate.use('bn');

    this._userInfoService.updateUserInfoMessage.subscribe((message) => {
      if (message == UPDATE_USER_INFO) {
        let accessJson = this._appStorageService.getData(
          ADMIN_LOGIN_MENUS_LOCAL_STORAGE_KEY
        );
        if (accessJson != null) {
          this.menus = JSON.parse(accessJson);
        }
      }
    });
  }

  changeLanguage(lan: string) {
    this.translate.use(lan);
    this.setActiveLanguageLink();
  }

  setActiveLanguageLink() {
    if (this.translate.currentLang === 'en') {
      $('#englishLangLink').addClass('active');
      $('#banglaLangLink').removeClass('active');
      document.documentElement.setAttribute('lang', 'en');
    } else if (this.translate.currentLang === 'bn') {
      $('#banglaLangLink').addClass('active');
      $('#englishLangLink').removeClass('active');
      document.documentElement.setAttribute('lang', 'bn');
    }
  }

  ngOnInit(): void {
    this.sidebarState = this.getSideBarState();
    // window.addEventListener('mouseup', function(event:any){
    //     var box = document.getElementById('sidebar');
    //     if (event.target != box && event.target.parentNode != box){
    //         box.style.display = 'block';

    //     }
    // });

    // window.onresize = this.resizeFn;
    // this.resizeFn();

    /* Loop through all dropdown buttons to toggle between hiding and showing its dropdown content - This allows the user to have multiple dropdowns without any conflict */
    var dropdown = document.getElementsByClassName('dropdown-btn');
    var i;

    for (i = 0; i < dropdown.length; i++) {
      dropdown[i].addEventListener('click', function () {
        this.classList.toggle('active');
        var dropdownContent = this.nextElementSibling;
        if (dropdownContent.style.display === 'block') {
          dropdownContent.style.display = 'none';
        } else {
          dropdownContent.style.display = 'block';
        }
      });
    }
  }

  //    resizeFn() {
  //       if (window.innerWidth>=0 && window.innerWidth<800) {
  //          // this.sidebarservice.setSidebarState(false);
  //          this.sidebarState=!this.sidebarState;
  //           console.log('triger from resize');
  //           console.log(this.sidebarState);

  //           }
  //   }

  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  toggle(currentMenu: any) {
    if (currentMenu.type === 'dropdown') {
      this.menus.forEach((element) => {
        if (element === currentMenu) {
          currentMenu.active = !currentMenu.active;
        } else {
          //element.active = false;
        }
      });
    }
  }

  getState(currentMenu: { active: any }) {
    if (currentMenu.active) {
      return 'down';
    } else {
      return 'up';
    }
  }

  ngAfterViewInit() {
    //setTimeout(() => this.setActiveLanguageLink());
  }

  hasBackgroundImage() {
    return this.sidebarservice.hasBackgroundImage;
  }

  logout(): void {
    //this._authService.logout();
    localStorage.clear();
    this._webPortalService.loginState.next(false);
    localStorage.setItem('loggedIn', 'false');
    this.router.navigate(['/']);
  }
}
