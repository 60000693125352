import { RequestAuth } from './../@utlity/model/request/request-auth';
import { RestClient } from './../@utlity/services/rest.client';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { CredentialsService } from './credentials.service';
import { API } from '@app/@utlity/constant/api';
import { APPLICATION_NAME, CLIENT_ID } from '@app/@utlity/constant/constant';
import { RequestLogin } from '@app/@utlity/model/request/request.login';
import { CustomerLogInResponseBody } from '@app/web/customer-login/model/customer-log-in-response-body';

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  customerLogInResponseBody: CustomerLogInResponseBody;

  private loggedInStatus = JSON.parse(
    localStorage.getItem('loggedIn') || 'false'
  );

  //private selectedPlanData = JSON.parse(localStorage.getItem('selectedPlanData') || 'false')
  //private selectedPlanData = JSON.parse(localStorage.getItem('selectedPlanData') || 'false')

  constructor(
    private credentialsService: CredentialsService,
    private restClient: RestClient
  ) {}

  login(
    context: LoginContext,
    token: string,
    locationInfo: any
  ): Observable<any> {
    const requestLogin = new RequestLogin(
      API.USER_AUTH_URL,
      'authenticate-user',
      APPLICATION_NAME,
      token,
      CLIENT_ID,
      context.username,
      context.password,
      JSON.stringify(locationInfo)
    );
    return this.restClient.post(
      API.CECURITY_URL + API.USER_AUTH_URL,
      requestLogin
    );
  }

  public authorizeApplication(applicationName: string): Observable<any> {
    const requestApp = new RequestAuth(
      API.APP_AUTH_URL,
      'authenticate-application',
      applicationName
    );
    return this.restClient.post(
      API.CECURITY_URL + API.APP_AUTH_URL,
      requestApp
    );
  }

  public authorizeClient(token: string): Observable<any> {
    const requestClient = new RequestAuth(
      API.CLIENT_AUTH_URL,
      'authenticate-client',
      APPLICATION_NAME,
      token,
      CLIENT_ID
    );
    return this.restClient.post(
      API.CECURITY_URL + API.CLIENT_AUTH_URL,
      requestClient
    );
  }

  isloggedIn(value: boolean) {
    if (this.customerLogInResponseBody?.loginStatus === 'OK') {
      this.loggedInStatus = value;
      return true;
    } else {
      return false;
    }
  }

  isUserLoggedIn(): boolean {
    return JSON.parse(
      localStorage.getItem('loggedIn') ||
        this.customerLogInResponseBody.toString()
    );
  }

  getUserLocationInfo(): Observable<any> {
    return this.restClient.get('https://ipapi.co/json');
  }

  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.credentialsService.setCredentials();
    return of(true);
  }
}
