<!-- Web Footer Section Starts -->
<div class="container footer-main">
  <div class="row menu-part">
    <div class="col-md-4">
      <div class="footer-left">
        <img src="../../../../assets/images/footer-logo.png" />
      </div>
    </div>
    <div class="col-md-8">
      <div class="footer-right-menu-part">
        <div class="row">
          <div class="col-6 col-sm-4">
            <div class="footer-menu">
              <h4>{{ 'About' | translate }}</h4>
              <ul>
                <li>
                  <a routerLink="#">{{ 'Company' | translate }}</a>
                </li>
                <li>
                  <a routerLink="#">{{ 'Leadership' | translate }}</a>
                </li>
                <li>
                  <a routerLink="#">{{ 'Careers' | translate }}</a>
                </li>
                <li>
                  <a routerLink="#">{{ 'Social_impact' | translate }}</a>
                </li>
                <li>
                  <a routerLink="#">{{ 'News_&_Articles' | translate }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-6 col-sm-4">
            <div class="footer-menu">
              <h4>{{ 'Insurance' | translate }}</h4>
              <ul>
                <li>
                  <a routerLink="/life-insurance/endowment-plan">{{
                    'Endowment_Plan' | translate
                  }}</a>
                </li>
                <li>
                  <a routerLink="/motor-insurance/private-motor-plan">{{
                    'Private_Motor_Plan' | translate
                  }}</a>
                </li>
                <li>
                  <a routerLink="/health-insurance">{{
                    'Health_Insurance' | translate
                  }}</a>
                </li>
                <li>
                  <a routerLink="/life-insurance/child-plan">{{
                    'Child_Insurance' | translate
                  }}</a>
                </li>
                <li>
                  <a routerLink="/travel-insurance">{{
                    'Travel_Insurance' | translate
                  }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-6 col-sm-4">
            <div class="footer-menu">
              <h4>{{ 'Legal' | translate }}</h4>
              <ul>
                <li>
                  <a routerLink="#">{{ 'Terms_of_Use' | translate }}</a>
                </li>
                <li>
                  <a routerLink="#">{{ 'Privacy_Policy' | translate }}</a>
                </li>
                <li>
                  <a routerLink="#">{{
                    'Bangladesh_Privacy_Policy' | translate
                  }}</a>
                </li>
                <li>
                  <a routerLink="#">{{ 'Personal_Information' | translate }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row download-apps-part">
    <div class="col-sm-5 footer-left">
      <p class="download-text" style="font-weight: 600">
        {{ 'DOWNLOAD_THE_APP' | translate }}
      </p>
      <div class="download-logos">
        <a routerLink="#"
          ><img src="../../../../assets/images/home-page/logo/google-play.png"
        /></a>
        <a routerLink="#"
          ><img src="../../../../assets/images/home-page/logo/app-store.png"
        /></a>
      </div>
    </div>
    <div class="col-sm-7 footer-right-download-part">
      <div class="social-icons">
        <a routerLink="#"><i class="fab fa-facebook"></i></a>
        <a routerLink="#"><i class="fab fa-instagram"></i></a>
        <a routerLink="#"><i class="fab fa-twitter"></i></a>
      </div>
      <p>© All rights reserved by beemabox.com/</p>
    </div>
  </div>
</div>
