import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

import { LoaderComponent } from './loader/loader.component';
import { TranslateModule } from '@ngx-translate/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CustomMaterialModule } from './custom-material.module';
import { ToastrModule } from 'ngx-toastr';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NumsToWordsPipe } from '@app/@utlity/constant/number-to-words-pipe';
import { SharedPipesModule } from './pipes/shared-pipe.module';
//import { BrowserModule } from '@angular/platform-browser';
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    //BrowserModule,
    //BrowserAnimationsModule,
    TranslateModule,
    SharedPipesModule,
    CommonModule,
    ToastrModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgSelectModule,
    CustomMaterialModule,
    PerfectScrollbarModule,
  ],
  declarations: [LoaderComponent],
  exports: [
    //BrowserModule,
    //BrowserAnimationsModule,
    TranslateModule,
    SharedPipesModule,
    CommonModule,
    LoaderComponent,
    //WebFooterComponent,
    ToastrModule,
    BsDatepickerModule,
    NgSelectModule,
    CustomMaterialModule,
    PerfectScrollbarModule,
  ],
})
export class SharedModule {}
