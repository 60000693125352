import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { extract } from './i18n';
import { AllInsuranceComponent } from './web/all-insurance/all-insurance.component';

const routes: Routes = [
  // Fallback when no prior route is matched
  //{ path: 'home', component: HomeComponent, data: { title: extract('Home') } },

  {
    path: 'app-admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    //import('./hoothut-application/common/app-admin/app-admin.module').then((m) => m.AppAdminModule),
    data: { title: extract('Admin') },
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./hoothut-application/common/app-admin/app-admin.module').then(
        (m) => m.AppAdminModule
      ),
    data: { title: extract('Admin') },
  },
  {
    path: 'insurer',
    loadChildren: () =>
      import(
        './hoothut-application/common/app-insurer/app-insurer.module'
      ).then((m) => m.AppInsurerModule),
    data: { title: extract('Insurer') },
  },
  {
    path: 'customer',
    loadChildren: () =>
      import(
        './hoothut-application/common/app-customer/app-customer.module'
      ).then((m) => m.AppCustomerModule),
    data: { title: extract('Customer') },
  },
  {
    path: 'registered-user',
    loadChildren: () =>
      import(
        './hoothut-application/common/app-registered-user/app-registered-user.module'
      ).then((m) => m.AppRegisteredUserModule),
    data: { title: extract('Registered User') },
  },
  {
    path: 'unit-promoter',
    loadChildren: () =>
      import(
        './hoothut-application/common/app-unit-promoter/app-unit-promoter.module'
      ).then((m) => m.AppUnitPromoterModule),
    data: { title: extract('Unit Promoter') },
  },
  {
    path: 'tem',
    loadChildren: () =>
      import('./hoothut-application/template/template.module').then(
        (m) => m.TemplateModule
      ),
    data: { title: extract('Template Life Insurance Company Ltd') },
  },
  {
    path: 'temn',
    loadChildren: () =>
      import('./hoothut-application/template/template.module').then(
        (m) => m.TemplateModule
      ),
    data: { title: extract('Template Non Life Insurance Company Ltd') },
  },

  {
    path: '',
    loadChildren: () => import('./web/web.module').then((m) => m.WebModule),
    data: { title: extract('HootHut | Insurance Bangladesh') },
  },
  { path: 'admin', redirectTo: 'admin/dashboard', pathMatch: 'full' },
  { path: 'insurer', redirectTo: 'insurer/dashboard', pathMatch: 'full' },
  { path: 'customer', redirectTo: 'insurer/dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
