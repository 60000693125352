import { BaseRequest } from './base-request';

export class Body {
    applicationName: string;
    authenticationToken: string;
    clientId: string;
}

export class RequestAuth extends BaseRequest {
    body: Body = new Body();
    constructor(url: string, requestSourceService: string, applicationName: string,
         authenticationToken?: string, clientId?: string) {
        super();
        this.header.requestType = url;
        this.header.requestSourceService = requestSourceService;
        this.body.applicationName = applicationName;
        this.body.authenticationToken = authenticationToken;
        this.body.clientId = clientId;

    }
}