<div class="login-container">
  <div class="container">
    <div class="wrapper">
      <div class="card-body custom-card-body" *ngIf="showSignInPage">
        <!-- <h4 class="card-title text-center">
            <i class="far fa-3x fa-user-circle text-muted"></i>
          </h4> -->

        <div class="logo">
          <img
            src="./../../../../assets/images/beemabox-square.jpg"
            alt="Beemabox Logo"
            style="cursor: pointer"
            (click)="gotoHomePage()"
          />
          <p translate>Account Login</p>
        </div>

        <form (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
          <div
            class="login-failed"
            *ngIf="this.isError"
            style="margin-bottom: 10px"
          >
            <p class="login-failed-text">
              <i class="fas fa-times-circle"></i>
              {{
                'Login Failed. Please check your credentials and try again'
                  | translate
              }}
            </p>
          </div>

          <div
            class="alert alert-danger"
            [hidden]="!error || isLoading"
            translate
          >
            Username or password incorrect.
          </div>

          <div class="form-group">
            <label class="d-block">
              <input
                type="text"
                class="form-control"
                formControlName="loginId"
                autocomplete="loginId"
                [placeholder]="'Username' | translate"
              />
              <span hidden translate>Username</span>
              <small
                [hidden]="
                  loginForm.controls.loginId.valid ||
                  loginForm.controls.loginId.untouched
                "
                translate
              >
                Username is required
              </small>
            </label>
          </div>

          <div class="form-group">
            <label class="d-block">
              <!-- <input type="password" class="form-control" formControlName="password" autocomplete="current-password"
                    [placeholder]="'Password' | translate" required /> -->
              <input
                type="password"
                class="form-control"
                formControlName="password"
                autocomplete="current-password"
                [placeholder]="'Password' | translate"
                required
              />
              <span hidden translate>Password</span>
              <small
                [hidden]="
                  loginForm.controls.password.valid ||
                  loginForm.controls.password.untouched
                "
                translate
              >
                Password is required
              </small>
            </label>
          </div>

          <div class="form-group">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  type="checkbox"
                  class="form-check-input"
                  formControlName="remember"
                />
                <span translate>Remember me</span>
              </label>
            </div>
          </div>

          <button
            class="btn btn-block signUp font-weight-bolder mt-4"
            type="submit"
            [disabled]="loginForm.invalid || isLoading"
          >
            <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
            <span translate>Login</span>
          </button>
        </form>
        <div class="form-signin">
          <button
            type="button"
            class="login-with-google-btn"
            type="submit"
            (click)="signInWithGoogle()"
          >
            Sign in with Google</button
          ><span>or</span>
          <button
            type="button"
            class="login-with-facebook-btn"
            type="submit"
            (click)="signInWithFB()"
          >
            Sign in with Facebook
          </button>
          <!-- <button class="btn btn-lg btn-google btn-block text-uppercase" type="submit" (click)="signInWithGoogle()"><i
              class="fab fa-google mr-2"></i> Sign in with Google</button>
          <button class="btn btn-lg btn-facebook btn-block text-uppercase" type="submit" (click)="signInWithFB()"><i
              class="fab fa-facebook-f mr-2"></i> Sign in with Facebook</button> -->
        </div>
        <hr />
        <!-- <form class="form-signin">
          <button class="btn btn-lg btn-google btn-block text-uppercase" type="submit"><i class="fab fa-google mr-2"></i> Sign in with Google</button> 
          <button class="btn btn-lg btn-facebook btn-block text-uppercase" type="submit"><i class="fab fa-facebook-f mr-2"></i> Sign in with Facebook</button>
        </form> -->
        <div class="text-center">
          <p>
            <span>{{ 'Dont_have_an_account' | translate }} </span>
            <a href="javascript:;" (click)="goToCustomerSignUpPage()"
              ><span>{{ 'Sign Up' | translate }}</span></a
            >
          </p>
        </div>
        <div class="d-flex justify-content-center">
          <a href="javascript:void(0)" (click)="goToForgotPasswordPage()">{{
            'Forgot your password?' | translate
          }}</a>
        </div>
      </div>

      <div *ngIf="showSignUpPage" class="card-body">
        <div class="logo">
          <img
            src="./../../../../assets/images/beemabox-square.jpg"
            alt="Beemabox Logo"
          />
          <p>{{ 'Account Sign Up' | translate }}</p>
        </div>
        <form
          focusInvalidInput
          [formGroup]="signUpForm"
          (ngSubmit)="signUpInformation()"
        >
          <div
            class="login-failed"
            *ngIf="showSignUpFailedMessgae"
            style="margin-bottom: 10px"
          >
            <p class="login-failed-text">
              <i class="fas fa-times-circle"></i>
              {{ signUpFailedMessgae }}
            </p>
          </div>

          <div class="input-group mt-3">
            <div class="input-group-prepend">
              <span class="input-group-text"
                ><i class="fa fa-phone" aria-hidden="true"></i
              ></span>
            </div>

            <input
              #mobileNo
              id="mobileNo"
              name="mobileNo"
              type="text"
              class="form-control"
              pattern="{{ mobileNoPattern }}"
              [placeholder]="'Enter_Your_Mobile_No_PH' | translate"
              formControlName="mobileNo"
              required
            />
          </div>

          <span
            *ngIf="
              signUpForm.get('mobileNo').invalid ||
              (signUpForm.get('mobileNo').invalid &&
                (signUpForm.get('mobileNo').dirty ||
                  signUpForm.get('mobileNo').touched))
            "
          >
            <small style="color: red">
              * {{ 'Please enter your valid mobile no.' | translate }}</small
            >
          </span>
          <!-- <span *ngIf="signUpForm.get('mobileNo').touched && signUpForm.get('mobileNo').hasError('mobileNo')">
            <small style="color: red;"> *Please enter a valid mobile no.</small>
          </span> -->
          <div class="input-group mt-3">
            <div class="input-group-prepend">
              <span class="input-group-text"
                ><i class="fa fa-envelope" aria-hidden="true"></i
              ></span>
            </div>
            <!-- <input id="email" type="email" class="form-control" placeholder="Enter your email" [email]="true" formControlName="email"> -->
            <input
              #email
              name="email"
              id="email"
              (blur)="emailAvailablity($event)"
              type="email"
              class="form-control"
              [placeholder]="'Enter_Your_Email_PH' | translate"
              [email]="true"
              formControlName="email"
            />
          </div>

          <!-- <span *ngIf="signUpForm.get('email').touched && !emailAvailable"><small style="color: red"> *Email not available.</small></span> -->
          <!-- <span *ngIf="signUpForm.get('email').touched"><small style="color: red"> *Email not available.</small></span> -->
          <div class="input-group mt-3">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fas fa-key"></i></span>
            </div>
            <input
              #password
              id="password"
              name="password"
              type="password"
              class="form-control"
              [placeholder]="'Create_Password_PH' | translate"
              formControlName="password"
              required
            />
          </div>
          <span
            *ngIf="
              signUpForm.get('password').invalid ||
              (signUpForm.get('password').invalid &&
                (signUpForm.get('password').dirty ||
                  signUpForm.get('password').touched)) ||
              signUpForm.get('password').hasError('required')
            "
          >
            <small style="color: red">
              *{{ 'Create a password.' | translate }}</small
            >
          </span>
          <button
            id="signUp"
            type="submit"
            class="btn btn-block signUp font-weight-bolder mt-4"
          >
            {{ 'Sign Up' | translate }}
          </button>
        </form>

        <hr />
        <div class="text-center">
          <p>
            <span>{{ 'Already have an account?' | translate }}</span>
            <a
              id="signInLink"
              href="javascript:;"
              (click)="goToCustomerLoginPage()"
              >{{ 'Sign In' | translate }}</a
            >
            <small> </small>
          </p>
        </div>
      </div>

      <div class="card-body" *ngIf="showForgotPasswordPage">
        <div class="logo">
          <img
            src="./../../../../assets/images/beemabox-square.jpg"
            alt="Beemabox Logo"
          />
          <p>{{ forgotPasswordTitle | translate }}</p>
        </div>

        <form focusInvalidInput [formGroup]="forgotPasswordForm">
          <div
            class="row"
            *ngIf="showForgotPasswordPage && showForgotPasswordLoginId"
          >
            <div class="form-group">
              <label class="d-block">
                <input
                  #loginId
                  name="loginId"
                  id="loginId"
                  type="text"
                  class="form-control"
                  formControlName="loginId"
                  autocomplete="loginId"
                  [placeholder]="'Enter_username_loginid_PH' | translate"
                />
                <span hidden>{{ 'Enter_username_loginid' | translate }}</span>
                <small
                  [hidden]="
                    forgotPasswordForm.controls.loginId.valid ||
                    forgotPasswordForm.controls.loginId.untouched
                  "
                >
                  {{ 'Username_loginid_is_required' | translate }}
                </small>
              </label>
            </div>

            <button type="submit" (click)="sendOtp()">
              <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
              <span translate>Next</span>
            </button>
          </div>

          <div
            class="row"
            *ngIf="showForgotPasswordPage && showForgotPasswordOtp"
          >
            <div class="form-group">
              <label class="d-block">
                <input
                  #otp
                  name="otp"
                  id="otp"
                  type="text"
                  max="6"
                  class="form-control"
                  formControlName="otp"
                  autocomplete="otp"
                  [placeholder]="'Enter_6_digit_otp_PH' | translate"
                />
                <span hidden translate>Enter_6_digit_otp</span>
                <small
                  [hidden]="
                    forgotPasswordForm.controls.otp.valid ||
                    forgotPasswordForm.controls.otp.untouched
                  "
                  translate
                >
                  otp_is_required
                </small>
              </label>
            </div>

            <button type="submit" (click)="verifyOtp()">
              <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
              <span translate>Verify</span>
            </button>
          </div>
        </form>
        <hr />
        <div class="text-center">
          <p>
            <a
              id="signInLink"
              href="javascript:;"
              (click)="goToCustomerLoginPage()"
              ><span>{{
                'Already have an account?
                Sign in' | translate
              }}</span></a
            >
            <small> </small>
          </p>
        </div>
      </div>

      <div class="card-body" *ngIf="showResetPasswordPage">
        <div class="logo">
          <img
            src="./../../../../assets/images/beemabox-square.jpg"
            alt="Beemabox Logo"
          />
          <p>Reset Password</p>
        </div>

        <form focusInvalidInput [formGroup]="resetPasswordForm">
          <div class="form-group">
            <label class="d-block">
              <!-- <input type="password" class="form-control" formControlName="password" autocomplete="current-password"
                    [placeholder]="'Password' | translate" required /> -->
              <input
                type="password"
                class="form-control"
                formControlName="newPassword"
                autocomplete="current-password"
                [placeholder]="'New Password' | translate"
                required
              />
              <span hidden translate>New Password</span>
              <small
                [hidden]="
                  resetPasswordForm.controls.newPassword.valid ||
                  resetPasswordForm.controls.newPassword.untouched
                "
                translate
              >
                New Password is required
              </small>
            </label>
          </div>

          <div class="form-group">
            <label class="d-block">
              <!-- <input type="password" class="form-control" formControlName="password" autocomplete="current-password"
                    [placeholder]="'Password' | translate" required /> -->
              <input
                type="password"
                class="form-control"
                formControlName="confirmNewPassword"
                autocomplete="current-password"
                [placeholder]="'Confirm New Password' | translate"
                required
              />
              <span hidden translate>Confirm New Password</span>
              <small
                [hidden]="
                  resetPasswordForm.controls.confirmNewPassword.valid ||
                  resetPasswordForm.controls.confirmNewPassword.untouched
                "
                translate
              >
                Confirm New Password is required
              </small>
            </label>
          </div>

          <div class="row">
            <button type="submit" (click)="resetPassword()">
              <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
              <span translate>Reset</span>
            </button>
          </div>

          <!-- <button
              type="submit"
              (click)="resetPassword()"
            >
              <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
              <span translate>Reset Password</span>
            </button> -->
        </form>
        <hr />
        <div class="text-center">
          <p>
            <a
              id="signInLink"
              href="javascript:;"
              (click)="goToCustomerLoginPage()"
              ><span>Already have an account? Sign in</span></a
            >
            <small> </small>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner></ngx-spinner>
