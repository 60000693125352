<div content-extra class="row">
  <div class="col-sm-3"></div>
  <div class="col-sm-3"></div>
  <div class="col-sm-3">
    <button (click)="getDownloadPdfReport()" type="button">
      <i class="fa fa-download"></i>&nbsp;&nbsp;Download Report
    </button>
  </div>
  <!-- <div class="col-sm-3">
      <button  (click)="getTableData()" class="btn btn-pill btn-block btn-cus-success" type="button">
                  <i class="fa fa-search"></i>&nbsp;&nbsp;{{ 'Search' | translate }}
      </button>
    </div> -->
  <div class="col-sm-3"></div>
</div>
