export class Login {
  fullName: string;
  email: string;
  username: string;
  loginId: string;
  insurerId: string;
  insurerType: string;
  mobileNo: string;

  loginStatus: string;
  authorizationKey: string;
  loginDateTime: Date;

  accessJson: string;
  roleDescription: string;
  roleType: string;
}
