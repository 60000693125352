import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASE_API_URL_COMMON, SECURITY_GET_USERINFO_BY_LOGIN_ID_API_V1, SECURITY_LOGIN_API_V1 } from '../constant/constant';
import { SignInRequest } from '../model/model/sign-in/sign-in-request';
import { SignInResponse } from '../model/model/sign-in/sign-in-response';

@Injectable({
  providedIn: 'root'
})
export class SignInService {

  constructor(private httpClient: HttpClient) { }

  doLogin(
    signInRequest: SignInRequest
  ): Observable<SignInResponse> {
    return this.httpClient.post<SignInResponse>(
      `${BASE_API_URL_COMMON}/${SECURITY_LOGIN_API_V1}`,
      signInRequest
    );
  }

}
