<div class="card-body">
    <button type="submit" class="close btn btn-outline-dark ml-1" (click)="closeModal()">
        X
    </button>
    <!-- <button
    type="button"
    class="close custom-close"
    aria-label="Close"
    (click)="closeModal()"
    >
    <img
        src="../../../../assets/images/home-page/logo/modal-close-button.png"
    />
  </button> -->

    <div class="logo">
        <img src="./../../../../assets/images/beemabox-square.jpg" alt="Beemabox Logo" />
        <p>Please enter the otp code verify your account</p>
        <span>A One-Time Otp code sent to {{otpObj.mobileNo}}</span>
    </div>

    <div class="content">
        <div class="o-c">
            <div>
                <div class="card">
                    <p class="a-o-i">Enter verification code</p>
                    <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" *ngIf="showOtpComponent" [config]="config"> </ng-otp-input>
                    <p style="padding-top: 5px;">OTP Expires in <countdown #otpCountDown [config]="countDownConfig"></countdown> Minutes</p>
                    <span *ngIf="otp" class="o-t-p">Entered otp :-{{otp}}</span>
                    <span *ngIf="showWrongOtpMessage" style="color: red;">{{otpVerifyMessage}}</span>
                    <!-- <span *ngIf="showWrongOtpMessage" style="color: red;">The OTP you entered is invalid. Please enter correct OTP.</span> -->
                    <!-- <span *ngIf="showWrongOtpMessage" style="color: red;">The OTP code has expired. Please re-send the otp code to try again.</span> -->
                </div>
            </div>
        </div>
    </div>
    <br />
    <p style="text-align: center;">
        <span>Don't receive the Code?</span> <br />
        <span style="color: red; font-weight: bold; cursor: pointer;" (click)="resendOtpByLoginId()"><a href="javascript:void">Resend</a></span><br />
        <span *ngIf="showResendOtpMessage" style="color: green;">OTP code was resent sucessfully.</span>
    </p>
</div>

<ngx-spinner></ngx-spinner>
