<nav
  id="sidebar"
  class="sidebar"
  [ngClass]="{ 'sidebar-bg': hasBackgroundImage() }"
>
  <div class="sidebar-content">
    <perfect-scrollbar>
      <div class="sidebar-header">
        <a routerLink="/">
          <img
            src="../../../../assets/images/home-page/logo/Beema-logo.png"
            alt="Beemabox Logo"
          />
        </a>
      </div>

      <!--collapsible sidebar dynamic menu-->
      <div class="sidebar-menu">
        <ul
          class="
            nav nav-pills
            flex-column
            mb-sm-auto mb-0
            align-items-center align-items-sm-start
          "
          id="menu"
        >
          <li class="nav-item" *ngFor="let menu of menus; let i = index">
            <a
              *ngIf="menu.type === 'dropdown'"
              data-bs-toggle="collapse"
              href="#menu_index_{{ i }}"
              role="button"
              aria-controls="multiCollapseExample1"
            >
              <i class="{{ menu.icon }}"></i>
              <span class="ms-1 d-inline">{{ menu.title | translate }}</span>
              <i
                *ngIf="menu.type === 'dropdown'"
                class="fas fa-chevron-down"
              ></i>
            </a>
            <a
              *ngIf="menu.type === 'header'"
              href="#menu_index_{{ i }}"
              role="button"
              href="#"
              onClick="return false"
            >
              <i class="{{ menu.icon }}"></i>
              <span class="ms-1 d-inline">{{ menu.title | translate }}</span>
              <i
                *ngIf="menu.type === 'dropdown'"
                class="fas fa-chevron-down"
              ></i>
            </a>
            <a
              *ngIf="menu.type !== 'dropdown' && menu.type !== 'header'"
              href="#menu_index_{{ i }}"
              role="button"
              routerLink="{{ menu.link }}"
            >
              <i class="{{ menu.icon }}"></i>
              <span class="ms-1 d-inline">{{ menu.title | translate }}</span>
              <i
                *ngIf="menu.type === 'dropdown'"
                class="fas fa-chevron-down"
              ></i>
            </a>
            <ul
              class="collapse multi-collapse"
              id="menu_index_{{ i }}"
              data-bs-parent="#menu"
              style="list-style-type: none"
              *ngIf="menu.type === 'dropdown'"
            >
              <li *ngFor="let submenu of menu.submenus">
                <a routerLink="{{ submenu.link }}" routerLinkActive="active">
                  <i class="{{ submenu.icon }}"></i>
                  {{ submenu.title | translate }}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </perfect-scrollbar>
  </div>
</nav>
