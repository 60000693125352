import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASE_API_URL, COMMON_GET_APP_SETTINGS_LIST_API_V1, COMMON_UPDATE_APP_SETTINGS_LIST_API_V1 } from '../constant/constant';
import { AppSettingsListRequest } from '../model/request/app-settings-list-request';
import { AppSettingsUpdateRequest } from '../model/request/app-settings-update-request';
import { AppSettingsListResponse } from '../model/response/app-settings-list-response';
import { AppSettingsUpdateResponse } from '../model/response/app-settings-update-response';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  
  constructor(private httpClient: HttpClient) {}

  getAppSettingsList(appSettingsListRequest: AppSettingsListRequest): Observable<AppSettingsListResponse> {
    return this.httpClient.post<AppSettingsListResponse>(
      `${BASE_API_URL}/${COMMON_GET_APP_SETTINGS_LIST_API_V1}`,
      appSettingsListRequest
    );
  }

  updateAppSettingsList(appSettingsUpdateRequest: AppSettingsUpdateRequest): Observable<AppSettingsUpdateResponse> {
    return this.httpClient.post<AppSettingsUpdateResponse>(
      `${BASE_API_URL}/${COMMON_UPDATE_APP_SETTINGS_LIST_API_V1}`,
      appSettingsUpdateRequest
    );
  }

}